/* eslint-disable multiline-ternary */
import {
  Box,
  Image,
  Text,
  Spacer,
  Flex,
  useToast,
  useMediaQuery,
} from '@chakra-ui/react'
import { Header, Footer, Sidebar, ToastTheme } from 'component'
import { Component, useEffect } from 'react'
import {
  Route,
  Routes,
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import {
  DiaryScreen,
  DiaryDetailScreen,
  DiaryWriteScreen,
  HomeScreen,
  ServiceScreen,
  TestScreen,
  LoginScreen,
  RegisterScreen,
  RegisterFinScreen,
  FindPasswordScreen,
  MyStateScreen,
  QuestionScreen,
  TestResultScreen,
  AccountScreen,
  CouponScreen,
  NotFoundScreen,
  FindIdScreen,
  NoticeScreen,
} from 'screen'
import {
  ImageLayoutList,
  ImageLayoutPage,
  MobileImageLayoutList,
} from 'data/ImageLayoutList'

function LoginLayout() {
  return <Outlet />
}
function BasicLayout() {
  const [isMobile] = useMediaQuery('(max-width: 480px)', {
    ssr: true,
    fallback: false,
  })

  return (
    <Box position="relative" h="100vh">
      <Header />
      {isMobile ? (
        <main>
          <Outlet />
          <Footer />
        </main>
      ) : (
        <main style={{ position: 'absolute', top: '0' }}>
          <Outlet />
          <Footer />
        </main>
      )}
    </Box>
  )
}
function MyLayout({ sidebar, bg }: { sidebar?: boolean; bg?: string }) {
  const toast = useToast()
  const navigate = useNavigate()
  const token = localStorage.getItem('access')
  const [isMobile] = useMediaQuery('(max-width: 480px)', {
    ssr: true,
    fallback: false,
  })

  useEffect(() => {
    if (token) {
      return
    }
    toast({
      description: '로그인이 필요한 서비스 입니다',
      ...ToastTheme.error,
    })
    navigate('/login', { replace: true })
  }, [token])

  return (
    <>
      {isMobile ? (
        <Outlet />
      ) : (
        <Flex h="100vh" bg={bg ?? 'white'}>
          {sidebar && (
            <>
              <Sidebar />
              <Box overflow="auto" flex="1" py="9.5rem" px="28">
                <Outlet />
              </Box>
            </>
          )}
          {!sidebar && <Outlet />}
        </Flex>
      )}
    </>
  )
}

function ImageLayout() {
  const toast = useToast()
  const navigate = useNavigate()
  const [isMobile] = useMediaQuery('(max-width: 480px)', {
    ssr: true,
    fallback: false,
  })
  const token = localStorage.getItem('access')
  const location = useLocation()
  const path = location.pathname
  const pathname: ImageLayoutPage = path.includes('/diary')
    ? '/diary'
    : path.includes('service')
    ? '/service'
    : '/test'

  useEffect(() => {
    if (token ?? pathname.includes('/service')) {
      return
    }
    toast({
      description: '로그인이 필요한 서비스 입니다',
      ...ToastTheme.error,
    })
    navigate('/login', { replace: true })
  }, [token])
  return (
    <>
      <Header />
      <Box>
        {!isMobile && (
          <Box as="section" position="relative" minW="1280px">
            <Image src={ImageLayoutList[pathname].img} />
            <Box position="absolute" top="7rem" left={44} color="primary.m2">
              <Text as="h1">{ImageLayoutList[pathname].title}</Text>
              <Spacer h="4" />
              <Text
                as="h4"
                dangerouslySetInnerHTML={{
                  __html: ImageLayoutList[pathname].desc,
                }}
              />
            </Box>
          </Box>
        )}
        {isMobile && (
          <Box as="section" position="relative">
            <Image src={MobileImageLayoutList[pathname].img} w="100%" />
            <Text
              as="h1"
              position="absolute"
              top="17px"
              px="20px"
              fontSize="18px"
              color="primary.m2"
              dangerouslySetInnerHTML={{
                __html: MobileImageLayoutList[pathname].title,
              }}
            />
          </Box>
        )}
      </Box>
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  )
}

export default class Router extends Component {
  render() {
    return (
      <Routes>
        <Route path="*" element={<NotFoundScreen />} />
        <Route path="/" element={<BasicLayout />}>
          <Route index path="" element={<HomeScreen />} />
        </Route>
        <Route path="/" element={<ImageLayout />}>
          <Route path="service" element={<ServiceScreen />} />
          <Route path="diary" element={<DiaryScreen />} />
          <Route path="diary/:id" element={<DiaryDetailScreen />} />
          <Route path="diary/write" element={<DiaryWriteScreen />} />
          <Route path="test" element={<TestScreen />} />
          <Route path="test/my" element={<MyStateScreen />} />
          <Route path="test/:name" element={<QuestionScreen />} />
          <Route path="test/:name/result" element={<TestResultScreen />} />
        </Route>
        <Route path="/" element={<LoginLayout />}>
          <Route path="login" element={<LoginScreen />} />
          <Route path="register" element={<RegisterScreen />} />
          <Route path="register/fin" element={<RegisterFinScreen />} />
        </Route>
        <Route path="/find" element={<LoginLayout />}>
          <Route path="password" element={<FindPasswordScreen />} />
          <Route path="id" element={<FindIdScreen />} />
        </Route>
        <Route path="/my" element={<MyLayout sidebar />}>
          <Route path="account" element={<AccountScreen />} />
          <Route path="coupon" element={<CouponScreen />} />
        </Route>
        <Route path="/my/notice" element={<MyLayout bg="primary.m4" />}>
          <Route path="" element={<NoticeScreen />} />
        </Route>
      </Routes>
    )
  }
}
