import { api } from '.'
import { Diary } from 'model'

class DiaryService {
  /**
   * 마음일기 작성
   * @param data
   * @param token
   * @returns
   */
  async create(
    data: Pick<Diary, 'date' | 'emotion' | 'event' | 'think' | 'free'> & {
      tags: string[]
    },
    token: string
  ) {
    return await api.post('diaries/', data, token)
  }

  /**
   * 마음일기 데이터 가져오기
   * @param id
   * @param token
   * @returns Diary
   */
  async getDiaryDetail(id: number, token: string) {
    return await api.get<Diary>(`diaries/${id}`, token)
  }

  /**
   * 해당 날짜의 마음일기 가져오기, 달력에서 날짜 누르면 api 실행
   * @param date
   * @param token
   * @returns Diary
   */
  async getDiaryDetailByDate(date: string, token: string) {
    return await api.get<Diary>(`diaries/dates/${date}`, token)
  }

  /**
   * 사용자의 전체 마음일기 목록 가져오기
   * @param token
   * @returns Diary[]
   */
  async getDiaries(token: string) {
    return await api.get<Diary[]>('diaries/', token)
  }

  /**
   * 해당 id를 가진 마음일기 작성 날짜 바로 다음 마음일기 가져오기
   * @param id
   * @param token
   * @returns Diary
   */
  async getDiaryNext(id: number, token: string) {
    return await api.get<Diary>(`diaries/${id}/next`, token)
  }

  /**
   * 해당 id를 가진 마음일기 작성 날짜 바로 이전 마음일기 가져오기
   * @param id
   * @param token
   * @returns Diary
   */
  async getDiaryPrev(id: number, token: string) {
    return await api.get<Diary>(`diaries/${id}/prev`, token)
  }

  /**
   * 현재 달력에 해당하는 마음일기 목록 가져오기
   * @param year
   * @param month
   * @param token
   * @returns Diary[]
   */
  async getCalendar(year: number, month: number, token: string) {
    return await api.get<Diary[]>(
      `diaries/calender?year=${year}&month=${month}`,
      token
    )
  }

  /**
   * 마음일기 댓글에 좋아요 누르기
   * @param id
   * @param token
   * @returns boolean
   */
  async likeAnswer(id: number, token: string) {
    return await api.post(`diaries/${id}/likes`, null, token)
  }
}

export default new DiaryService()
