import { Text, SimpleGrid, Spacer } from '@chakra-ui/react'
import { Modal, Button } from 'component'
import { useRecoilValue } from 'recoil'
import { testExpiration } from 'store'

type DisclosureObject = {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  getDisclosureProps: () => void
  getButtonProps: () => void
}

export function CheckVerifyModal({
  id,
  state,
  isMobile,
}: {
  id: number
  state: DisclosureObject
  isMobile: boolean
}) {
  const testExp = useRecoilValue(testExpiration)

  return (
    <Modal title="마음검사 기간 안내" state={state} isMobile={isMobile}>
      <Text as="b">
        마음검사는 한 달에 한 번씩 진행됩니다.
        <br /> 다음 검사일은{' '}
        <Text color="primary.m2" as="span">
          {testExp[id - 1]}
        </Text>{' '}
        입니다.
      </Text>
      <Spacer h={6} />
      <SimpleGrid columns={2} gap={2.5}>
        <Button
          style={{ borderRadius: 'xl' }}
          theme="gray"
          onClick={state.onClose}
        >
          취소
        </Button>
        <Button style={{ borderRadius: 'xl' }} onClick={state.onClose}>
          확인
        </Button>
      </SimpleGrid>
    </Modal>
  )
}
