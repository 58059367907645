import axios, { AxiosRequestConfig, Method } from 'axios'
import dayjs from 'dayjs'
class RequestConfig {
  public baseURL: string
  public headers?: any
  public method?: Method
  public url?: string
  public data?: any

  constructor(baseURL: string) {
    this.baseURL = baseURL
  }

  public setToken(token?: string | null) {
    if (token) {
      this.headers = {
        Authorization: `Bearer ${token}`,
      }
    }
  }

  public get(path: string, config?: AxiosRequestConfig) {
    this.method = 'GET'
    this.url = this.baseURL + path

    return { ...config, ...this }
  }

  public delete(path: string, data?: unknown, config?: AxiosRequestConfig) {
    this.method = 'DELETE'
    this.url = this.baseURL + path
    this.data = data

    return { ...config, ...this }
  }

  public post(path: string, data?: unknown, config?: AxiosRequestConfig) {
    this.method = 'POST'
    this.url = this.baseURL + path
    this.data = data

    return { ...config, ...this }
  }

  public put(path: string, data?: unknown, config?: AxiosRequestConfig) {
    this.method = 'PUT'
    this.url = this.baseURL + path
    this.data = data

    return { ...config, ...this }
  }

  public patch(path: string, data?: unknown, config?: AxiosRequestConfig) {
    this.method = 'PATCH'
    this.url = this.baseURL + path
    this.data = data

    return { ...config, ...this }
  }
}

class BaseService {
  private validateToken(token: string) {
    if (token === '') {
      return false
    }
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    const parseToken = JSON.parse(window.atob(base64))
    return !dayjs(parseToken.exp * 1000).isBefore(dayjs(), 'day')
  }

  private getConfig(token?: string): RequestConfig {
    const requestConfig = new RequestConfig('https://server.drpresso.co.kr/')
    if (token) {
      requestConfig.setToken(token)
    }
    return requestConfig
  }

  async get<Data = any>(path: string, token?: string): Promise<Data> {
    return (
      await axios(this.getConfig(token).get(path, { withCredentials: true }))
    ).data as Data
  }

  async delete<Data = any>(
    path: string,
    data?: unknown,
    token?: string
  ): Promise<Data> {
    return (
      await axios(
        this.getConfig(token).delete(path, data, { withCredentials: true })
      )
    ).data as Data
  }

  async post<Data = any>(
    path: string,
    data: unknown,
    tokenOrConfig?: string | AxiosRequestConfig
  ): Promise<Data> {
    const token = typeof tokenOrConfig === 'string' ? tokenOrConfig : undefined
    const config = typeof tokenOrConfig !== 'string' ? tokenOrConfig : undefined

    return (
      await axios(
        this.getConfig(token).post(path, data, {
          ...config,
          withCredentials: true,
        })
      )
    ).data as Data
  }

  async put<Data = any>(
    path: string,
    data: unknown,
    token?: string
  ): Promise<Data> {
    return (
      await axios(
        this.getConfig(token).put(path, data, { withCredentials: true })
      )
    ).data as Data
  }

  async patch<Data = any>(
    path: string,
    data: unknown,
    token?: string
  ): Promise<Data> {
    return (
      await axios(
        this.getConfig(token).patch(path, data, { withCredentials: true })
      )
    ).data as Data
  }
}

export const api = new BaseService()
