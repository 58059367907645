import { useState, useRef, useEffect } from 'react'
import { useMutation } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import {
  Box,
  Flex,
  Spacer,
  Text,
  useMediaQuery,
  useToast,
  VStack,
  Button,
} from '@chakra-ui/react'
import { PageTitle, QuestionCard, ToastTheme } from 'component'
import { QuestionList } from 'data/question'
import { TestCategory } from 'data/testCategory'
import { Question, TestType } from 'model'
import { TestService } from 'service'

export default function QuestionScreen() {
  const navigate = useNavigate()
  const token = localStorage.getItem('access') ?? ''
  const toast = useToast()
  const param = useParams()
  const test: TestType = (param.name as unknown as TestType) ?? 'PHQ'
  const [scoreBoard, setScoreBoard] = useState<number[]>([])
  const refList: any[] = Array.from({ length: 20 }, () => useRef(null))
  const [isMobile] = useMediaQuery('(max-width: 480px)', {
    ssr: true,
    fallback: false,
  })
  let total = -1

  useEffect(() => {
    setScoreBoard(Array.from({ length: 20 }, () => -1))
  }, [test, param])

  const createTest = useMutation(
    'create test',
    async () => {
      if (checkValid()) {
        addScore()
      } else {
        throw new Error('모든 항목을 입력해주세요.')
      }
      return await TestService.createTest(
        { testCategoryId: TestCategory[test].id, score: total },
        token
      )
    },
    {
      onSuccess: () => {
        navigate(`/test/${test}/result`)
      },
    }
  )
  const checkValid = () => {
    for (let i = 1; i <= QuestionList[test].length; i++) {
      if (scoreBoard[i] === -1) {
        toast({ description: '모든 문항에 답변해주세요.', ...ToastTheme.error })
        refList[i - 1].current.scrollIntoView({ behavior: 'smooth' })
        return false
      }
    }
    return true
  }
  const addScore = () => {
    let t = 0
    for (let s = 1; s <= QuestionList[test].length; s++) {
      t += scoreBoard[s]
    }
    total = t
  }

  return (
    <Box bg={isMobile ? 'gray.g4' : 'white'} minW={isMobile ? '' : '1280px'}>
      <PageTitle title={TestCategory[test].title} isMobile={isMobile} />
      <VStack
        px={isMobile ? '20px' : 44}
        spacing={isMobile ? '15px' : 6}
        align="stretch"
      >
        <Text
          as="h4"
          py={isMobile ? '16px' : 9}
          px={isMobile ? '20px' : '4.5rem'}
          bg="primary.m2"
          color="white"
          borderRadius="2xl"
          boxShadow={isMobile ? '' : 'md'}
          fontSize={isMobile ? '15px' : ''}
        >
          {TestCategory[test].desc}
        </Text>
        {QuestionList[test].map((q: Question) => {
          return (
            <QuestionCard
              key={q.id}
              ref={refList[q.id - 1]}
              question={q}
              isMobile={isMobile}
              setScore={(s: number) => {
                scoreBoard[q.id] = s
              }}
            />
          )
        })}
      </VStack>
      <Spacer h={isMobile ? '40px' : 20} />
      <Flex
        justify="center"
        gap={2.5}
        pb={isMobile ? '60px' : '8.25rem'}
        px={isMobile ? '20px' : ''}
      >
        <Button
          w={isMobile ? '100%' : ''}
          h={isMobile ? '56px' : '40px'}
          py={isMobile ? 0 : 2}
          px={isMobile ? 0 : 16}
          bg="#8D8D8D"
          color="white"
          fontSize={isMobile ? '15px' : ''}
          fontWeight={isMobile ? 'bold' : ''}
          borderRadius={isMobile ? '12px' : 'md'}
          onClick={() => {
            navigate('/test/my')
          }}
        >
          취소
        </Button>
        <Button
          w={isMobile ? '100%' : ''}
          h={isMobile ? '56px' : '40px'}
          py={isMobile ? 0 : 2}
          px={isMobile ? 0 : 16}
          bg="primary.m2"
          color="white"
          fontSize={isMobile ? '15px' : ''}
          fontWeight={isMobile ? 'bold' : ''}
          borderRadius={isMobile ? '12px' : 'md'}
          onClick={() => {
            createTest.mutate()
          }}
        >
          제출
        </Button>
      </Flex>
    </Box>
  )
}
