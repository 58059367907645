import { Input, Text, VStack, Spacer, Box, useToast } from '@chakra-ui/react'
import { Button, ToastTheme } from 'component'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { AuthService } from 'service'

export function VerifyCode({
  email,
  goNextStep,
  isMobile,
}: {
  email: string
  goNextStep: () => void
  isMobile: boolean
}) {
  const toast = useToast()
  const [code, setCode] = useState<string>('')

  const checkAuthCode = useMutation(['checkAuthCode'], async () => {
    try {
      await AuthService.checkAuthCode({ email, token: code })
      toast({
        description: '인증되었습니다.',
        ...ToastTheme.success,
      })
      goNextStep()
    } catch (err) {
      toast({
        description: '이메일을 다시 확인해주세요.',
        ...ToastTheme.error,
      })
      throw new Error('invalid token')
    }
  })

  return (
    <Box w={isMobile ? '100%' : '350px'}>
      <Text textStyle="smallBold" textAlign="left" mb={isMobile ? '12px' : ''}>
        인증 코드가 포함된 이메일이 {email}(으)로 전송되었습니다.
      </Text>
      {!isMobile && <Spacer h={6} />}
      <VStack gap={isMobile ? '40px' : 2}>
        <Input
          placeholder="인증코드"
          h={isMobile ? '50px' : '40px'}
          w={isMobile ? '100%' : '350px'}
          bg={isMobile ? '#F7F7F7' : 'white'}
          variant={isMobile ? 'filled' : 'outline'}
          value={code}
          onChange={(e) => {
            setCode(e.target.value)
          }}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              checkAuthCode.mutate()
            }
          }}
        />
        <Button
          style={
            isMobile
              ? {
                  w: '100%',
                  borderRadius: 'lg',
                  fontWeight: 'bold',
                  h: '56px',
                }
              : { w: '100%', borderRadius: 'lg' }
          }
          onClick={() => {
            checkAuthCode.mutate()
          }}
        >
          다음
        </Button>
      </VStack>
    </Box>
  )
}
