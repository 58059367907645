import axios from 'axios'

const getCurrentLocation = async (): Promise<GeolocationPosition> => {
  return await new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject)
  })
}

export const getCurrentAddress = async (): Promise<string> => {
  try {
    const position: GeolocationPosition = await getCurrentLocation()
    const response = await axios.get(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${position.coords.latitude}&lon=${position.coords.longitude}&accept-language=ko`
    )
    return response.data.address.city
  } catch (error) {
    return ''
  }
}
