import {
  Box,
  Flex,
  Spacer,
  Text,
  useDisclosure,
  Button,
} from '@chakra-ui/react'

import { TestCategory } from 'data/testCategory'
import { TestResult, TestType } from 'model'

import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { CheckVerifyModal } from './CheckVerifyModal'
// import { verifyTest } from 'helpers'

export function TestDateCard({
  data,
  isMobile,
}: {
  data: {
    id: number
    name: TestType
    test: TestResult[]
  }
  isMobile: boolean
}) {
  const navigate = useNavigate()
  const state = useDisclosure()
  // const enable: boolean = verifyTest(data.id)

  const goTest = () => {
    // if (enable) {
    navigate(`/test/${data.name}`)
    // } else {
    //   state.onOpen()
    // }
  }

  return (
    <Flex
      bg="primary.m4"
      borderRadius="2xl"
      p={isMobile ? '16px' : 14}
      align={isMobile ? 'start' : 'center'}
      justify="space-between"
      flexDirection={isMobile ? 'column' : 'row'}
    >
      <CheckVerifyModal id={data.id} state={state} isMobile={isMobile} />
      <Text
        as="h4"
        fontSize={isMobile ? '18px' : ''}
        w="200px"
        pb={isMobile ? '12px' : ''}
      >
        {TestCategory[data.name].name}
      </Text>
      <Box flex="1">
        <Text as="b" fontSize={isMobile ? '15px' : ''}>
          {TestCategory[data.name].fullname}
        </Text>
        <Spacer h={2} />
        <Text fontSize={isMobile ? '15px' : ''} mb={isMobile ? '20px' : ''}>
          {data.test.length > 0
            ? dayjs(data.test[0].createdAt).format('YYYY년 M월 D일')
            : '검사결과 없음'}
        </Text>
      </Box>
      <Button
        w={isMobile ? '100%' : ''}
        h={isMobile ? '56px' : '40px'}
        py={isMobile ? 0 : 2}
        px={isMobile ? 0 : 7}
        bg="primary.m2"
        color="white"
        fontSize={isMobile ? '15px' : ''}
        fontWeight={isMobile ? 'bold' : ''}
        borderRadius={isMobile ? '12px' : '999'}
        onClick={goTest}
      >
        검사받기
      </Button>
    </Flex>
  )
}
