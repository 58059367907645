import { Box, Grid, Spacer, Text, Flex, useMediaQuery } from '@chakra-ui/react'

export function Footer() {
  const [isMobile] = useMediaQuery('(max-width: 480px)', {
    ssr: true,
    fallback: false,
  })
  return (
    <>
      {!isMobile && (
        <Flex
          as="footer"
          w="100%"
          minW="1280px"
          h="240px"
          bg="gray.g1"
          color="white"
          px={44}
          pb={16}
          align="flex-end"
          justify="space-between"
        >
          <Box>
            <Text as="h4">DOCTORPRESSO</Text>
            <Spacer h={6} />
            <Grid templateColumns="120px auto">
              <Text as="b">Email</Text>
              <Text as="a" type="email" href="mailto: ask@drpresso.com">
                ask_mindstation@drpresso.com
              </Text>
              <Text as="b">Address</Text>
              <Text as="span">
                서울특별시 동작구 흑석로 84 중앙대학교 108관 204호
              </Text>
            </Grid>
          </Box>
          <Text textStyle="small">
            COPYRIGHT ⓒ DOCTORPRESSO. ALL RIGHTS RESERVED
          </Text>
        </Flex>
      )}
      {isMobile && (
        <Flex
          as="footer"
          flexDirection="column"
          w="100%"
          h="172px"
          bg="gray.g1"
          color="white"
          px="20px"
          py="24px"
        >
          <Box>
            <Text as="h4" fontSize="17px">
              DOCTORPRESSO
            </Text>
            <Spacer h="16px" />
            <Grid templateColumns="auto auto" gap="8px">
              <Text as="b" fontSize="12px">
                Email
              </Text>
              <Text
                as="a"
                type="email"
                href="mailto: ask@drpresso.com"
                fontSize="13px"
              >
                ask_mindstation@drpresso.com
              </Text>
              <Text as="b" fontSize="12px">
                Address
              </Text>
              <Text as="span" fontSize="13px">
                서울특별시 동작구 흑석로 84 중앙대학교 108관 204호
              </Text>
            </Grid>
          </Box>
          <Spacer h="16px" />
          <Text fontSize="12px">
            COPYRIGHT ⓒ DOCTORPRESSO. ALL RIGHTS RESERVED
          </Text>
        </Flex>
      )}
    </>
  )
}
