/* eslint-disable multiline-ternary */
import {
  Box,
  Text,
  Spacer,
  Flex,
  useMediaQuery,
  Button,
} from '@chakra-ui/react'
import { Calender, DiaryCard } from 'component'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { DiaryService } from 'service'

export default function DiaryScreen() {
  const token = localStorage.getItem('access') ?? ''
  const navigate = useNavigate()
  const [isMobile] = useMediaQuery('(max-width: 480px)', {
    ssr: true,
    fallback: false,
  })
  const { data, isLoading } = useQuery(
    ['diaries'],
    async () => await DiaryService.getDiaries(token)
  )

  return (
    <>
      <Box
        as="section"
        bg="gray.g4"
        px={isMobile ? '20px' : 44}
        py={isMobile ? '32px' : '4.5rem'}
        minW={isMobile ? '' : '1280px'}
      >
        <Text as="h3" fontSize={isMobile ? '18px' : ''}>
          지난 마음일기
        </Text>
        <Spacer h={5} />
        <Flex
          justify="flex-start"
          gap={6}
          overflowX={isMobile ? 'auto' : 'hidden'}
          sx={
            isMobile
              ? {
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                  scrollbarWidth: 'none',
                  '-ms-overflow-style': 'none',
                }
              : {}
          }
        >
          {!isLoading &&
            data?.map((item) => {
              return (
                <DiaryCard key={item.id} diary={item} isMobile={isMobile} />
              )
            })}
        </Flex>
      </Box>
      <Box
        as="section"
        bg="white"
        px={isMobile ? '20px' : 44}
        py={isMobile ? '32px' : '4.5rem'}
        minW={isMobile ? '' : '1280px'}
      >
        <Text as="h3" fontSize={isMobile ? '18px' : ''}>
          오늘의 마음일기
        </Text>
        <Spacer h={6} />
        <Flex
          flexDirection={isMobile ? 'column' : 'row'}
          align={isMobile ? '' : 'center'}
          justify="space-between"
          bg={isMobile ? 'white' : 'gray.g4'}
          px={isMobile ? 0 : 12}
          py={isMobile ? 0 : 10}
          borderRadius="2xl"
        >
          <Box>
            <Text
              as="h4"
              fontSize={isMobile ? '15px' : ''}
              pb={isMobile ? '8px' : ''}
            >
              오늘 하루 당신의 마음이 어땠는지 기록해 보세요.
            </Text>
            <Text fontSize={isMobile ? '15px' : ''} pb={isMobile ? '20px' : ''}>
              {isMobile ? (
                <>
                  <span>마음일기에 당신의 하루를 적는 것만으로도</span>
                  <br />
                  <span>마음이 한결 가벼워질거예요.</span>
                </>
              ) : (
                '마음일기에 당신의 하루를 적는 것만으로도 마음이 한결 가벼워질거예요.'
              )}
            </Text>
          </Box>
          <Button
            w={isMobile ? '100%' : ''}
            h={isMobile ? '56px' : '40px'}
            py={isMobile ? 0 : 2}
            px={isMobile ? 0 : 7}
            bg="primary.m2"
            color="white"
            fontSize={isMobile ? '15px' : ''}
            fontWeight={isMobile ? 'bold' : ''}
            borderRadius={isMobile ? '12px' : '999'}
            onClick={() => {
              navigate('/diary/write')
            }}
          >
            마음일기 쓰기
          </Button>
        </Flex>
      </Box>
      <Box
        as="section"
        bg="primary.m4"
        px={isMobile ? '20px' : 44}
        py={isMobile ? '32px' : '4.5rem'}
        minW={isMobile ? '' : '1280px'}
      >
        <Text as="h3" fontSize={isMobile ? '18px' : ''}>
          마음일기 캘린더
        </Text>
        <Calender />
      </Box>
    </>
  )
}
