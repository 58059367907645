import { Box, useMediaQuery, VStack } from '@chakra-ui/react'
import { PageTitle, ResultCard } from 'component'

export default function MyStateScreen() {
  const [isMobile] = useMediaQuery('(max-width: 480px)', {
    ssr: true,
    fallback: false,
  })

  return (
    <Box bg={isMobile ? 'gray.g4' : 'white'} minW={isMobile ? '' : '1280px'}>
      <PageTitle title="나의 마음상태" isMobile={isMobile} />
      <VStack
        align="stretch"
        px={isMobile ? '20px' : 44}
        gap={isMobile ? '12px' : 14}
        mt={isMobile ? 0 : 14}
        pb={isMobile ? '60px' : 20}
      >
        <ResultCard name="PHQ" isMobile={isMobile} />
        <ResultCard name="ISI" isMobile={isMobile} />
        <ResultCard name="SSI" isMobile={isMobile} />
      </VStack>
    </Box>
  )
}
