import { Spacer, Text, SimpleGrid } from '@chakra-ui/react'
import { DisclosureObject, Modal, Button } from 'component'
import dayjs from 'dayjs'

export function YesterdayModal({
  state,
  onYes,
  onNo,
  isMobile,
}: {
  state: DisclosureObject
  onYes: (arg: string) => void
  onNo: () => void
  isMobile: boolean
}) {
  const getYesterday = () => {
    return dayjs(new Date().toLocaleString('en-US', { timeZone: 'Asia/Seoul' }))
      .subtract(1, 'd')
      .format('YYYY-MM-DD')
  }

  return (
    <Modal title="어제 날짜로 등록하기" state={state} isMobile={isMobile}>
      <Text mb="3" fontSize={isMobile ? '15px' : ''}>
        지금은 자정이 지난 새벽이에요. <br />
        작성하신 일기를 어제 날짜로 등록하기 원하시나요?
      </Text>
      <Text
        textStyle="small"
        color="gray.g2"
        sx={{ _before: { content: '"• "' } }}
        fontSize={isMobile ? '13px' : ''}
      >
        새벽 4시 전까지는 오늘의 일기로 등록할 수 있어요.
      </Text>
      <Spacer h={6} />
      <SimpleGrid columns={2} gap={2.5}>
        <Button style={{ borderRadius: 'xl' }} theme="gray" onClick={onNo}>
          아니요
        </Button>
        <Button
          style={{ borderRadius: 'xl' }}
          onClick={() => {
            onYes(getYesterday())
          }}
        >
          네
        </Button>
      </SimpleGrid>
    </Modal>
  )
}
