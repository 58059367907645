/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Spacer, Text, Link, Flex } from '@chakra-ui/react'
import { Button } from 'component'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { AuthService } from 'service'
import { Link as RLink } from 'react-router-dom'

export function FindId({
  email,
  isMobile,
}: {
  email: string
  isMobile: boolean
}) {
  const [id, setId] = useState<string>('')
  useEffect(() => {
    findId.mutate()
  }, [])

  const findId = useMutation(
    ['findIdByEmail'],
    async () => {
      return await AuthService.findIdByEmail({ email })
    },
    {
      onSuccess: (data) => {
        setId(data)
      },
    }
  )

  return (
    <>
      {isMobile ? (
        <Flex
          w="100%"
          h="100%"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            flex="1"
            flexDirection="column"
          >
            <Text textStyle="smallBold" textAlign="center">
              회원가입 시 사용한 아이디는{' '}
            </Text>
            <Text textStyle="smallBold">
              <Text color="primary.m2" as="span">
                {id}
              </Text>
              입니다.
            </Text>
          </Flex>
          <Link as={RLink} to="/login" mb="40px">
            <Button style={{ borderRadius: 'xl', width: '100%' }}>
              로그인
            </Button>
          </Link>
        </Flex>
      ) : (
        <Box w="350px">
          <Text textStyle="smallBold">
            회원가입 시 사용한 아이디는
            <Text color="primary.m2" as="span">
              {id}
            </Text>
            입니다.
          </Text>
          <Spacer h={6} />
          <Link as={RLink} to="/login">
            <Button style={{ borderRadius: 'xl', w: '100%' }}>로그인</Button>
          </Link>
        </Box>
      )}
    </>
  )
}
