/* eslint-disable multiline-ternary */
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'

import {
  Box,
  Divider,
  Flex,
  Heading,
  IconButton,
  Spacer,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import { OutlineButton } from 'component'
import { getCategory } from 'helpers'
import { FilterOption, UserNotification } from 'model'
import { FaTimes } from 'react-icons/fa'
import { NotificationService } from 'service'
import { MobileHeader } from 'component/mobile'

type FilterList = {
  label: string
  value: FilterOption
}

const filterList: FilterList[] = [
  { label: '전체', value: 'all' },
  { label: '마음일기', value: 'diary' },
  { label: '마음검사', value: 'test' },
  { label: '공지', value: 'notice' },
  { label: '기타', value: 'etc' },
]

export default function NoticeScreen() {
  const token = localStorage.getItem('access') ?? ''
  const navigate = useNavigate()
  const [filter, setFilter] = useState<FilterOption>('all')
  const [notices, setNotices] = useState<UserNotification[]>([])
  const [getNotification, setGetNotification] = useState<boolean>(false)
  const [isMobile] = useMediaQuery('(max-width: 480px)', {
    ssr: true,
    fallback: false,
  })
  const NoticeElem = ({ notice }: { notice: UserNotification }) => {
    const deleteNotification = useMutation(
      ['deleteNotification', notice.id],
      async (id: number) => {
        return await NotificationService.deleteNotification(id, token)
      },
      {
        onSuccess: () => {
          setGetNotification(true)
        },
      }
    )

    const readNotification = useMutation(
      ['readNotification', notice.id],
      async (id: number) => {
        return await NotificationService.readNotification(id, token)
      },
      {
        onSuccess: () => {
          setGetNotification(true)
        },
      }
    )

    const selectNotification = (notification: UserNotification) => {
      readNotification.mutate(notification.id)

      const id: string = notification?.diaryId?.toString() ?? ''
      const category: string = notification?.testCategory?.name as string

      if (notification.notification.title === null) {
        navigate('/my/notice')
      }
      switch (notification.notification.title) {
        case 'answer':
          navigate(`/diary/${id}`)
          break
        case 'write':
          navigate('/diary/write')
          break
        case 'testDate':
          navigate(`/test/${category}`)
          break
        case 'test':
          navigate('/test')
          break
      }
    }
    return (
      <>
        <Flex
          py={5}
          align="center"
          _hover={{ bg: 'gray.g4' }}
          px={2}
          cursor="pointer"
          onClick={() => {
            selectNotification(notice)
          }}
        >
          <Box flex="1">
            <Flex mb={2}>
              <Text
                as="b"
                display="inline-block"
                w="110px"
                color={notice.isRead ? 'gray.g2' : 'black'}
              >
                {getCategory(notice.notification.category)}
              </Text>
              <Text as="b" color="gray.g2">
                {dayjs(notice.createdAt).subtract(9, 'h').format('M월 D일')}
              </Text>
            </Flex>
            <Text color={notice.isRead ? 'gray.g2' : 'black'}>
              {notice.notification.contents}
            </Text>
          </Box>
          <IconButton
            aria-label="notice check button"
            icon={<FaTimes />}
            bg="transparent"
            borderRadius="full"
            onClick={(e) => {
              e.stopPropagation()
              deleteNotification.mutate(notice.id)
            }}
          />
        </Flex>
        <Divider borderColor="gray.g3" />
      </>
    )
  }

  useQuery(
    ['getAllNotificationsWithFilter'],
    async () => {
      return await NotificationService.getAllNotification(filter, token)
    },
    {
      enabled: getNotification,
      refetchOnWindowFocus: true,
      onSuccess: (data) => {
        setNotices(data)
        setGetNotification(false)
      },
    }
  )

  useEffect(() => {
    setGetNotification(true)
  }, [notices])

  return (
    <>
      {isMobile ? (
        <>
          <MobileHeader />
          <Flex flexDir="column" align="center" px="20px" py="27px">
            <Flex gap={6}>
              {filterList.map((item) => (
                <Text
                  key={item.label}
                  color={item.value === filter ? 'primary.m2' : 'gray.g2'}
                  textStyle="smallBold"
                  cursor="pointer"
                  whiteSpace="nowrap"
                  sx={
                    item.value === filter
                      ? {
                          textDecoration: 'underline',
                          textDecorationColor: 'primary.m2',
                          textDecorationThickness: '2px',
                          textUnderlineOffset: '10px',
                        }
                      : {}
                  }
                  onClick={() => {
                    setFilter(item.value)
                    setGetNotification(true)
                  }}
                >
                  {item.label}
                </Text>
              ))}
            </Flex>
            {notices.length > 0 &&
              notices.map((notice) => (
                <NoticeElem key={notice.id} notice={notice} />
              ))}
            {notices.length === 0 && (
              <Text
                display="inline-block"
                w="100%"
                textAlign="center"
                mt="20px"
                fontSize="13px"
              >
                새로운 알림이 없습니다.
              </Text>
            )}
          </Flex>
        </>
      ) : (
        <Box px={44} py={36} w="100%" overflow="auto">
          <Heading as="h1">알림</Heading>
          <Spacer h={12} />
          <Box
            w="100%"
            bg="white"
            boxShadow="sm"
            px={24}
            py={12}
            borderRadius="xl"
          >
            <Text as="h3">전체 알림</Text>
            <Spacer h={6} />
            <Flex gap={2}>
              {filterList.map((item) => {
                return (
                  <OutlineButton
                    key={item.value}
                    status={item.value === filter}
                    onClick={() => {
                      setFilter(item.value)
                      setGetNotification(true)
                    }}
                  >
                    {item.label}
                  </OutlineButton>
                )
              })}
            </Flex>
            <Spacer h={6} />
            <Divider borderColor="gray.g3" />
            {notices.length > 0 &&
              notices.map((notice) => (
                <NoticeElem key={notice.id} notice={notice} />
              ))}
            {notices.length === 0 && (
              <Text display="inline-block" w="100%" textAlign="center" my={2}>
                새로운 알림이 없습니다.
              </Text>
            )}
          </Box>
          <Text
            as="b"
            color="gray.g2"
            textAlign="center"
            display="inline-block"
            w="100%"
            py="14"
          >
            최근 30일 동안 받은 알림을 모두 확인했습니다.
          </Text>
        </Box>
      )}
    </>
  )
}
