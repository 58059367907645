/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Flex,
  Box,
  VStack,
  Text,
  HStack,
  Spacer,
  useToast,
  useDisclosure,
  Image,
  IconButton,
  Icon,
  useMediaQuery,
} from '@chakra-ui/react'
import {
  Button,
  InputForm,
  SelectForm,
  ToastTheme,
  VerifyEmailModal,
} from 'component'

import { useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'

import { AuthService } from 'service'
import { validEmail, validPassword } from 'helpers'
import Logo from 'asset/logo_Bright.png'
import { FaRegCheckCircle } from 'react-icons/fa'
import { MobileHeader } from 'component/mobile'

type Data = {
  message: string[]
  statusCode: number
}

const year = Array.from({ length: 90 }, (v, i) => i + 1930)
const month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
const day = Array.from({ length: 31 }, (v, i) => i + 1)

export default function RegisterScreen() {
  const toast = useToast()
  const navigate = useNavigate()
  const modal = useDisclosure()

  const [nick, setNick] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [gender, setGender] = useState<string>('')
  const [birthY, setBirthY] = useState<string>('')
  const [birthM, setBirthM] = useState<string>('')
  const [birthD, setBirthD] = useState<string>('')
  const [nickAvailable, setNickAvailable] = useState<boolean>(true)
  const [emailVerified, setEmailVerified] = useState<boolean>(false)
  const [check, setCheck] = useState<boolean>(false)

  const [isMobile] = useMediaQuery('(max-width: 480px)', {
    ssr: true,
    fallback: false,
  })

  const signup = useMutation(
    ['signup'],
    async () =>
      await AuthService.signup({
        nick,
        email,
        password,
        gender: gender === '' ? undefined : gender,
        birth:
          birthY === '' || birthM === '' || birthD === ''
            ? undefined
            : `${birthY}-${birthM}-${birthD}`,
      }),
    {
      onSuccess: (data) => {
        localStorage.setItem('access', data.access)
        navigate('/register/fin')
      },
      onError: (error: AxiosError) => {
        const message = (error.response?.data as Data).message
        toast({
          description: message,
          ...ToastTheme.error,
        })
      },
    }
  )

  const checkId = useMutation(
    ['checkId'],
    async () => {
      try {
        return await AuthService.checkId({ nick })
      } catch (err) {
        setNickAvailable(false)
        throw new Error('error')
      }
    },
    {
      onSuccess: (data) => {
        if (data) {
          setNickAvailable(true)
          toast({
            description: '사용가능한 아이디입니다.',
            ...ToastTheme.success,
          })
        } else {
          setNickAvailable(false)
        }
      },
    }
  )

  const checkEmailValid = useMutation(
    ['checkEmailValid'],
    async () => {
      return await AuthService.checkEmailValid({ email })
    },
    {
      onSuccess: (data) => {
        if (data) {
          toast({
            description: '사용 중인 메일 입니다. 다른 메일로 가입해주세요.',
            ...ToastTheme.error,
          })
          setEmail('')
        } else {
          sendAuthEmail.mutate()
        }
      },
    }
  )

  const sendAuthEmail = useMutation(
    ['sendAuthEmail'],
    async () => {
      return await AuthService.sendAuthEmail({ email })
    },
    {
      onSuccess: () => {
        toast({
          description: '인증번호가 전송되었습니다.',
          ...ToastTheme.success,
        })
        modal.onOpen()
      },
    }
  )

  return (
    <>
      {isMobile && <MobileHeader />}
      <Flex
        bg={isMobile ? 'white' : 'primary.m4'}
        flexDir="column"
        align="center"
        justify="center"
        overflow="auto"
        py={isMobile ? '27px' : 16}
        px={isMobile ? '20px' : ''}
      >
        <VerifyEmailModal
          state={modal}
          email={email}
          resend={() => {
            sendAuthEmail.mutate()
          }}
          verify={() => {
            setEmailVerified(true)
          }}
        />
        <VStack w={isMobile ? '100%' : '540px'} gap={3}>
          {!isMobile && <Image src={Logo} w="154px" />}
          {[
            {
              id: 1,
              label: '아이디',
              value: nick,
              setValue: setNick,
              isValid: nickAvailable,
              error: '사용중인 아이디 입니다. 다른 아이디를 입력해주세요.',

              Btn: {
                label: '중복확인',
                onClick: () => {
                  checkId.mutate()
                },
                disabled: nick.length === 0,
              },
            },
            {
              id: 2,
              label: '이메일',
              value: email,
              setValue: setEmail,
              type: 'email',
              error: '이메일 형식에 맞게 입력해주세요',
              isValid: email.length === 0 || validEmail(email),
              Btn: {
                label: '인증하기',
                onClick: () => {
                  checkEmailValid.mutate()
                },
                disabled: email.length === 0 || !validEmail(email),
              },
            },
            {
              id: 3,
              label: '비밀번호',
              value: password,
              setValue: setPassword,
              type: 'password',
              error: '8자 이상, 영문, 숫자, 특수문자를 포함해주세요',
              isValid: password.length === 0 || validPassword(password),
            },
            {
              id: 4,
              label: '비밀번호 확인',
              value: confirmPassword,
              setValue: setConfirmPassword,
              type: 'password',
              error: '입력한 비밀번호와 동일하게 입력해주세요',
              isValid: password === confirmPassword,
            },
          ].map((item) => {
            return <InputForm item={item} key={item.id} />
          })}
          <Box w={isMobile ? '100%' : '540px'}>
            <Text as="b" mb={2} display="block">
              성별{' '}
              <Text as="span" color="gray.g2">
                (선택)
              </Text>
            </Text>
            <SelectForm
              placeholder="성별"
              value={gender}
              setValue={setGender}
              isMobile={isMobile}
              option={[
                { value: 'Male', label: '남' },
                { value: 'Female', label: '여' },
              ]}
            />
          </Box>
          <Box w={isMobile ? '100%' : '540px'}>
            <Text as="b" mb={2} display="block">
              생년월일{' '}
              <Text as="span" color="gray.g2">
                (선택)
              </Text>
            </Text>
            <HStack gap={3}>
              <SelectForm
                placeholder="년"
                value={birthY}
                setValue={setBirthY}
                option={year}
                isMobile={isMobile}
              />
              <SelectForm
                placeholder="월"
                value={birthM}
                setValue={setBirthM}
                option={month}
                isMobile={isMobile}
              />
              <SelectForm
                placeholder="일"
                value={birthD}
                setValue={setBirthD}
                option={day}
                isMobile={isMobile}
              />
            </HStack>
          </Box>
          <Box w={isMobile ? '100%' : '540px'}>
            <Text as="b" mb={2} display="block">
              개인정보 수집•이용 동의{' '}
              <Text as="span" color="primary.m2">
                (필수)
              </Text>
            </Text>
            <Box
              bg="white"
              p={2}
              borderWidth="1px"
              borderColor="gray.g3"
              h="200px"
              overflow="auto"
            >
              개인정보보호법에 따라 닥터프레소에 회원가입 신청하시는 분께
              수집하는 개인정보의 항목, 개인정보의 수집 및 이용목적, 동의 거부권
              및 동의 거부 시 불이익에 관한 사항을 안내드리오니 자세히 읽은 후
              동의하여 주시기 바랍니다. <br />
              <br />
              <b>1. 수집하는 개인정보</b>
              <br />
              닥터프레소는 서비스 이용을 위해 필요한 최소한의 개인정보를
              수집합니다. 회원 가입 시 필수항목으로 아이디, 비밀번호,
              이메일주소를, 선택항목으로 성별, 생년월일을 수집합니다. 서비스
              이용 과정에서 이용자의 연력, 성별, 일기, 설문, 기분 기록, 연락처
              등이 수집될 수 있습니다. <br />
              <br />
              <b>2. 수집한 개인정보의 이용</b>
              <br />
              닥터프레소 및 닥터프레소 관련 제반 서비스(모바일 웹/앱 포함)의
              회원관리, 서비스 개발・제공 및 향상, 안전한 인터넷 이용환경 구축
              등 아래의 목적으로만 개인정보를 이용합니다. 닥터프레소와 Open AI가
              서비스 개선 목적으로 사용할 수 있으며, 콘텐츠의 소유권은 이용자가
              보유하지만 닥터프레소 및 Open AI 콘텐츠 사용 라이선스를
              부여합니다.
              <br />
              <br />
              <b>3. 개인정보 수집 및 이용 동의를 거부할 권리</b> <br />
              이용자는 개인정보의 수집 및 이용 동의를 거부할 권리가 있습니다.
              회원가입 시 수집하는 최소한의 개인정보, 즉, 필수 항목에 대한 수집
              및 이용 동의를 거부하실 경우, 회원가입이 어려울 수 있습니다.
            </Box>
            <Flex
              py={2}
              align="center"
              gap={2}
              onClick={() => {
                setCheck(!check)
              }}
              cursor="pointer"
            >
              <Icon
                as={FaRegCheckCircle}
                w="25px"
                h="25px"
                color={check ? 'primary.m2' : 'gray.g2'}
              />
              <Text as="b">개인정보 수집 및 이용에 동의합니다.</Text>
            </Flex>
          </Box>
          <Spacer h={2} />
          <Button
            style={{ w: '100%', borderRadius: 'lg' }}
            onClick={() => {
              signup.mutateAsync()
            }}
            disabled={
              !check ||
              !nick ||
              !nickAvailable ||
              !emailVerified ||
              !validEmail(email) ||
              !validPassword(password) ||
              password !== confirmPassword
            }
          >
            가입하기
          </Button>
        </VStack>
      </Flex>
    </>
  )
}
