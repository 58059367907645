import { Question } from 'model'

export const ISI: Question[] = [
  {
    id: 1,
    question: '잠들기 어렵다.',
    answer: [
      { score: 0, desc: '없음' },
      { score: 1, desc: '약간' },
      { score: 2, desc: '중간' },
      { score: 3, desc: '심함' },
      { score: 4, desc: '매우 심함' },
    ],
  },
  {
    id: 2,
    question: '잠을 유지하기 어렵다.',
    answer: [
      { score: 0, desc: '없음' },
      { score: 1, desc: '약간' },
      { score: 2, desc: '중간' },
      { score: 3, desc: '심함' },
      { score: 4, desc: '매우 심함' },
    ],
  },
  {
    id: 3,
    question: '너무 일찍 깬다.',
    answer: [
      { score: 0, desc: '없음' },
      { score: 1, desc: '약간' },
      { score: 2, desc: '중간' },
      { score: 3, desc: '심함' },
      { score: 4, desc: '매우 심함' },
    ],
  },
  {
    id: 4,
    question: '현재 당신의 수면에 얼마나 만족하는가?',
    answer: [
      { score: 0, desc: '매우 만족' },
      { score: 1, desc: '만족' },
      { score: 2, desc: '중간' },
      { score: 3, desc: '불만족' },
      { score: 4, desc: '매우 불만족' },
    ],
  },
  {
    id: 5,
    question:
      '수면문제로 일상생활에 얼마나 방해를 받는가? (예: 주간 피로감, 집중력, 기억력, 기분 등)',
    answer: [
      { score: 0, desc: '전혀 안됨' },
      { score: 1, desc: '약간' },
      { score: 2, desc: '어느 정도' },
      { score: 3, desc: '많이' },
      { score: 4, desc: '매우 많이' },
    ],
  },
  {
    id: 6,
    question:
      '당신이 수면문제로 생활에 어려움을 겪는다는 것을 주변 사람들이 아는가?',
    answer: [
      { score: 0, desc: '전혀 모름' },
      { score: 1, desc: '약간' },
      { score: 2, desc: '어느 정도' },
      { score: 3, desc: '많이' },
      { score: 4, desc: '매우 많이' },
    ],
  },
  {
    id: 7,
    question: '현재 수면문제에 관해 얼마나 걱정하고 있는가?',
    answer: [
      { score: 0, desc: '전혀 안함' },
      { score: 1, desc: '약간' },
      { score: 2, desc: '어느 정도' },
      { score: 3, desc: '많이' },
      { score: 4, desc: '매우 많이' },
    ],
  },
]
