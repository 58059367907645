import {
  IconButton,
  Modal as CModal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { ReactNode } from 'react'
import { FaTimes } from 'react-icons/fa'
import { MdClose } from 'react-icons/md'

export type ModalProp = {
  state: DisclosureObject
  title: string
  children: ReactNode
  isMobile?: boolean
}

export type DisclosureObject = {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  getDisclosureProps: () => void
  getButtonProps: () => void
}

export function Modal({ state, title, children, isMobile }: ModalProp) {
  return (
    <CModal
      isOpen={state.isOpen}
      onClose={state.onClose}
      size={isMobile ? 'sm' : 'xl'}
    >
      <ModalOverlay />
      <ModalContent
        py={isMobile ? '40px' : 20}
        px={isMobile ? 0 : 20}
        position={isMobile ? 'fixed' : 'relative'}
        top="20%"
      >
        <ModalHeader as="h3" fontSize={isMobile ? '18px' : '24px'}>
          {title}
        </ModalHeader>
        <IconButton
          position="absolute"
          top={isMobile ? '20px' : '60px'}
          right={isMobile ? '20px' : '60px'}
          icon={isMobile ? <MdClose /> : <FaTimes />}
          borderRadius={999}
          fontSize={isMobile ? '24px' : '30px'}
          color={isMobile ? '#3D3D3D' : 'gray.g2'}
          aria-label="close button"
          bg={isMobile ? '#F7F7F7' : 'transparent'}
          _hover={{ bg: 'transparent' }}
          _active={{ transform: 'scale(0.98)' }}
          onClick={state.onClose}
        />

        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </CModal>
  )
}
