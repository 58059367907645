import { User } from 'model/User'
import { api } from '.'

class UserService {
  async getUser(token: string): Promise<User> {
    return await api.get('users/', token)
  }

  async changePassword(
    input: { password: string; newPassword: string },
    token: string
  ) {
    return await api.post('users/password/', input, token)
  }

  async changeUserInfo(
    input: Pick<User, 'nick' | 'birth' | 'gender'>,
    token: string
  ) {
    return await api.patch('users/', input, token)
  }
}

export default new UserService()
