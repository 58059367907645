import { Box, Radio, RadioGroup, Spacer, Stack, Text } from '@chakra-ui/react'
import { Answer, Question } from 'model'
import { forwardRef } from 'react'

type QuestionCardProp = {
  question: Question
  setScore: (s: number) => void
  isMobile: boolean
}

function QuestionCard(props: QuestionCardProp, ref: any) {
  const question = props.question
  const setValue = (s: string) => {
    props.setScore(Number(s))
  }

  return (
    <Box
      px={props.isMobile ? '16px' : '4.5rem'}
      py={props.isMobile ? '16px' : 14}
      boxShadow={props.isMobile ? '' : 'sm'}
      bg="white"
      borderRadius="2xl"
      ref={ref}
    >
      <Text as="b">
        {question.id}. {question.question}
      </Text>
      <Spacer h={props.isMobile ? '12px' : 10} />
      <RadioGroup
        onChange={(s) => {
          setValue(s)
        }}
      >
        <Stack spacing={2} direction="column">
          {question.answer.map((a: Answer) => {
            return (
              <Radio
                key={a.desc}
                color="primary.m2"
                size="md"
                value={a.score.toString()}
              >
                <Text fontSize={props.isMobile ? '15px' : ''}>{a.desc}</Text>
              </Radio>
            )
          })}
        </Stack>
      </RadioGroup>
    </Box>
  )
}
export default forwardRef(QuestionCard)
