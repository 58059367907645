import { Location } from 'model'
import { api } from '.'

class LocationService {
  async get() {
    return await api.get<Location>('location')
  }
}

export default new LocationService()
