import {
  Box,
  Button,
  Divider,
  Flex,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  Text,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { getCategory } from 'helpers'
import { UserNotification } from 'model'
import { useEffect, useState, ReactNode } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { NotificationService } from 'service'

const TextBtn = ({
  children,
  onClick,
}: {
  children: ReactNode
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}) => {
  return (
    <Text
      onClick={onClick}
      color="gray.g2"
      textStyle="small"
      cursor="pointer"
      _hover={{
        textDecoration: 'underline',
        textUnderlinePosition: 'under',
      }}
    >
      {children}
    </Text>
  )
}

const NotificationElem = ({
  notification,
  setGetNotification,
}: {
  notification: UserNotification
  setGetNotification: (v: boolean) => void
}) => {
  const navigate = useNavigate()
  const token = localStorage.getItem('access') ?? ''

  const readNotification = useMutation(
    ['readNotificationHeader', notification.id],
    async (id: number) => {
      return await NotificationService.readNotification(id, token)
    },
    {
      onSuccess: () => {
        setGetNotification(true)
      },
    }
  )

  const selectNotification = (notification: UserNotification) => {
    readNotification.mutate(notification.id)

    const id: string = notification?.diaryId?.toString() ?? ''
    const category: string = notification?.testCategory?.name as string

    if (notification.notification.title === null) {
      navigate('/my/notice')
    }
    switch (notification.notification.title) {
      case 'answer':
        navigate(`/diary/${id}`)
        break
      case 'write':
        navigate('/diary/write')
        break
      case 'testDate':
        navigate(`/test/${category}`)
        break
      case 'test':
        navigate('/test')
        break
    }
  }

  const deleteNotification = useMutation(
    ['deleteNotification', notification.id],
    async (id: number) => {
      return await NotificationService.deleteNotification(id, token)
    },
    {
      onSuccess: () => {
        setGetNotification(true)
      },
    }
  )

  return (
    <Flex
      px={2}
      py={3}
      justify="space-between"
      gap={1.5}
      borderBottomWidth={1}
      borderColor="gray.g3"
      _hover={{ bg: 'gray.g4' }}
      cursor="pointer"
      onClick={() => {
        selectNotification(notification)
      }}
    >
      <Text
        textStyle="smallBold"
        w="85px"
        color={notification.isRead ? 'gray.g2' : 'black'}
      >
        {getCategory(notification.notification.category)}
      </Text>
      <Text
        overflow="hidden"
        textOverflow="ellipsis"
        flex="1"
        whiteSpace="nowrap"
        textStyle="small"
        h="20px"
        color={notification.isRead ? 'gray.g2' : 'black'}
      >
        {notification.notification.contents}
      </Text>
      <Flex gap={2}>
        <Text textStyle="small" color="gray.g2">
          {dayjs(notification.createdAt).subtract(9, 'h').format('M월 D일')}
        </Text>
        <TextBtn
          onClick={(e) => {
            e.stopPropagation()
            deleteNotification.mutate(notification.id)
          }}
        >
          ✕
        </TextBtn>
      </Flex>
    </Flex>
  )
}
export const NotificationBody = ({
  setFetchCount,
}: {
  setFetchCount: (v: boolean) => void
}) => {
  const navigate = useNavigate()
  const token = localStorage.getItem('access') ?? ''
  const [notifications, setNotifications] = useState<UserNotification[]>([])

  const [getNotification, setGetNotification] = useState<boolean>(false)

  useEffect(() => {
    if (token) {
      setGetNotification(true)
    }
  }, [token])

  useQuery(
    ['getAllNotifications'],
    async () => {
      return await NotificationService.getAllNotification('all', token)
    },
    {
      enabled: getNotification,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setNotifications(data)
        setGetNotification(false)
        setFetchCount(true)
      },
    }
  )

  const deleteNotifications = useMutation(
    ['deleteNotifications'],
    async (read: boolean) => {
      return await NotificationService.deleteAllNotification(read, token)
    },
    {
      onSuccess: () => {
        setGetNotification(true)
        setFetchCount(true)
      },
    }
  )

  return (
    <PopoverContent color="gray.g1" borderRadius={0}>
      <PopoverArrow />
      <PopoverBody p={0}>
        <Flex flexDir="column" justify="space-between" h="300px">
          <Flex p={2} justify="space-between">
            <Text textStyle="smallBold" color="primary.m2">
              전체 알림
            </Text>
            <Flex gap={4}>
              <TextBtn
                onClick={() => {
                  deleteNotifications.mutate(true)
                }}
              >
                읽은 알림 삭제
              </TextBtn>
              <TextBtn
                onClick={() => {
                  deleteNotifications.mutate(false)
                }}
              >
                전체 알림 삭제
              </TextBtn>
            </Flex>
          </Flex>
          <Divider borderColor="gray.g2" borderWidth="2" />
          <Box flex="1" overflow="auto" width="100%">
            {notifications.length > 0 &&
              notifications.map((notification) => (
                <NotificationElem
                  key={notification.id}
                  notification={notification}
                  setGetNotification={setGetNotification}
                />
              ))}
          </Box>
          <Button
            borderRadius={0}
            bg="gray.g4"
            width="100%"
            borderTopWidth={1}
            borderColor="gray.g3"
            onClick={() => {
              navigate('/my/notice')
            }}
          >
            알림 전체 보기
          </Button>
        </Flex>
      </PopoverBody>
    </PopoverContent>
  )
}
