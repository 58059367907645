/* eslint-disable multiline-ternary */
import {
  Box,
  Flex,
  Icon,
  Input,
  Spacer,
  Text,
  useDisclosure,
  useMediaQuery,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { Button, Card, Modal, ToastTheme } from 'component'
import { Coupon } from 'model'
import { useState } from 'react'
import { FaInfoCircle } from 'react-icons/fa'
import { useMutation, useQuery } from 'react-query'
import { CouponService, UserService } from 'service'
import dayjs from 'dayjs'
import { MobileHeader, MobileMyNav } from 'component/mobile'

export default function CouponScreen() {
  const toast = useToast()
  const [name, setName] = useState<string>('')
  const openInfo = useDisclosure()
  const token = localStorage.getItem('access') ?? ''
  const [coupon, setCoupon] = useState<Coupon[]>([])
  const [isMobile] = useMediaQuery('(max-width: 480px)', {
    ssr: false,
    fallback: false,
  })
  const { refetch } = useQuery(
    ['user'],
    async () => await UserService.getUser(token),
    {
      onSuccess: (data) => {
        setCoupon(data.coupons)
      },
    }
  )

  const register = useMutation(
    ['register coupon'],
    async () => {
      return await CouponService.create({ name }, token)
    },
    {
      onSuccess: (data) => {
        if (data) {
          toast({
            description: '이용권이 등록되었습니다.',
            ...ToastTheme.success,
          })
          refetch()
        }
      },
      onError: (err: any) => {
        const error: Error = err.response.data as Error
        toast({
          description: error.message,
          ...ToastTheme.error,
        })
      },
    }
  )
  const CouponInfoModal = () => {
    return (
      <Modal state={openInfo} title="이용권 등록 안내" isMobile={isMobile}>
        <Text sx={{ _before: { content: '"• "' } }}>
          이용권은 1회만 등록가능합니다.
        </Text>
        <Text sx={{ _before: { content: '"• "' } }}>
          이용권의 대소문자를 정확히 입력해 주세요.
        </Text>
        <Spacer h={6} />
        <Button
          style={{ borderRadius: 'xl', width: '100%' }}
          onClick={openInfo.onClose}
        >
          확인
        </Button>
      </Modal>
    )
  }
  return (
    <>
      {isMobile ? (
        <>
          <MobileHeader />
          <MobileMyNav />
          <CouponInfoModal />
          <Flex flexDir="column" w="100%" px="20px" pt="27px" pb="60px">
            <Text as="h1" fontSize="20px" mb="24px">
              이용권 등록
            </Text>
            <VStack gap={6} align="stretch">
              <Box>
                <Text as="b" display="inline-block" fontSize="15px" mb={3}>
                  이용권 만료일
                </Text>
                {coupon.length === 0 ? (
                  <Text fontSize="15px" color="gray.g2">
                    등록된 이용권이 없습니다.
                  </Text>
                ) : (
                  <Text fontSize="15px" color="red">
                    {dayjs(coupon[0].expiredAt).format('YYYY년 MM월 DD일')}
                  </Text>
                )}
              </Box>
              <Box>
                <Flex justify="space-between" mb={3} align="center">
                  <Text as="b" fontSize="15px">
                    이용권 등록
                  </Text>
                  <Icon
                    as={FaInfoCircle}
                    color="gray.g2"
                    cursor="pointer"
                    onClick={openInfo.onOpen}
                  />
                </Flex>
                {coupon.length === 0 ? (
                  <Input
                    display="block"
                    placeholder="쿠폰 코드"
                    value={name}
                    onChange={(e) => {
                      e.preventDefault()
                      setName(e.target.value)
                    }}
                    onKeyDown={(e) => {
                      if (e.code === 'Enter') {
                        register.mutate()
                      }
                    }}
                    bg="#F7F7F7"
                    fontSize="15px"
                    h="50px"
                    variant="filled"
                  />
                ) : (
                  <Text fontSize="15px">{coupon[0].coupon.name}</Text>
                )}
              </Box>
              <Button
                onClick={() => {
                  register.mutate()
                }}
                disabled={coupon.length !== 0}
                style={{
                  borderRadius: 'xl',
                  width: '100%',
                  fontWeight: 'bold',
                  fontSize: '15px',
                }}
              >
                등록
              </Button>
            </VStack>
          </Flex>
        </>
      ) : (
        <Box minW={isMobile ? '' : '1280px'}>
          <CouponInfoModal />
          <Text as="h1">이용권 관리</Text>
          <Spacer h={10} />
          <Card title="이용권 등록">
            <VStack gap={6} align="stretch">
              <Box>
                <Text as="b" display="inline-block" mb={3}>
                  이용권 만료일
                </Text>
                {coupon.length === 0 ? (
                  <Text color="gray.g2">등록된 이용권이 없습니다.</Text>
                ) : (
                  <Text color="red">
                    {dayjs(coupon[0].expiredAt).format('YYYY년 MM월 DD일')}
                  </Text>
                )}
              </Box>
              <Box>
                <Flex justify="space-between" mb={3} align="center">
                  <Text as="b">이용권 등록</Text>
                  <Icon
                    as={FaInfoCircle}
                    color="gray.g2"
                    cursor="pointer"
                    onClick={openInfo.onOpen}
                  />
                </Flex>
                {coupon.length === 0 ? (
                  <Input
                    display="block"
                    placeholder="이용권 등록"
                    value={name}
                    onChange={(e) => {
                      e.preventDefault()
                      setName(e.target.value)
                    }}
                    onKeyDown={(e) => {
                      if (e.code === 'Enter') {
                        register.mutate()
                      }
                    }}
                    w="350px"
                  />
                ) : (
                  <Text>{coupon[0].coupon.name}</Text>
                )}
              </Box>
              <Button
                onClick={() => {
                  register.mutate()
                }}
                disabled={coupon.length !== 0}
                style={{ borderRadius: 'xl', width: '350px' }}
              >
                등록
              </Button>
            </VStack>
          </Card>
        </Box>
      )}
    </>
  )
}
