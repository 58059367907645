import { Emoji } from 'asset/emoji'

export const EmojiList = [
  {
    id: 1,
    name: '기쁜',
    emoji: Emoji['1'],
    status: false,
  },
  {
    id: 2,
    name: '행복한',
    emoji: Emoji['2'],
    status: false,
  },
  {
    id: 3,
    name: '활기찬',
    emoji: Emoji['3'],
    status: false,
  },
  {
    id: 4,
    name: '만족스러운',
    emoji: Emoji['4'],
    status: false,
  },
  {
    id: 5,
    name: '편안한',
    emoji: Emoji['5'],
    status: false,
  },
  {
    id: 6,
    name: '설레는',
    emoji: Emoji['6'],
    status: false,
  },
  {
    id: 7,
    name: '슬픈',
    emoji: Emoji['7'],
    status: false,
  },
  {
    id: 8,
    name: '우울한',
    emoji: Emoji['8'],
    status: false,
  },
  {
    id: 9,
    name: '상실감 드는',
    emoji: Emoji['9'],
    status: false,
  },
  {
    id: 10,
    name: '지루한',
    emoji: Emoji['10'],
    status: false,
  },
  {
    id: 11,
    name: '피곤한',
    emoji: Emoji['11'],
    status: false,
  },
  {
    id: 12,
    name: '외로운',
    emoji: Emoji['12'],
    status: false,
  },
  {
    id: 13,
    name: '무기력한',
    emoji: Emoji['13'],
    status: false,
  },
  {
    id: 14,
    name: '좌절스러운',
    emoji: Emoji['14'],
    status: false,
  },
  {
    id: 15,
    name: '번아웃된',
    emoji: Emoji['15'],
    status: false,
  },
  {
    id: 16,
    name: '화난',
    emoji: Emoji['16'],
    status: false,
  },
  {
    id: 17,
    name: '짜증난',
    emoji: Emoji['17'],
    status: false,
  },
  {
    id: 18,
    name: '질투나는',
    emoji: Emoji['18'],
    status: false,
  },
  {
    id: 19,
    name: '불안한',
    emoji: Emoji['19'],
    status: false,
  },
  {
    id: 20,
    name: '스트레스 받는',
    emoji: Emoji['20'],
    status: false,
  },
  {
    id: 21,
    name: '놀라운',
    emoji: Emoji['21'],
    status: false,
  },
  {
    id: 22,
    name: '불편한',
    emoji: Emoji['22'],
    status: false,
  },
  {
    id: 23,
    name: '미안한',
    emoji: Emoji['23'],
    status: false,
  },
  {
    id: 24,
    name: '연민이 드는',
    emoji: Emoji['24'],
    status: false,
  },
  {
    id: 25,
    name: '부끄러운',
    emoji: Emoji['25'],
    status: false,
  },
  {
    id: 26,
    name: '무감정한',
    emoji: Emoji['26'],
    status: false,
  },
]
