export type color = 'white' | 'gray' | 'yellow' | 'primary' | 'red'

export const colors = {
  white: '#ffffff',
  gray: {
    g1: '#434343',
    g2: '#949494',
    g3: '#D8D8D8',
    g4: '#F5F5F5',
  },
  yellow: '#FBB500',
  primary: {
    m0: '#272C5B',
    m1: '#745BE6',
    m2: '#5078DF',
    m3: '#55AFEF',
    m4: '#EFF5FF',
    m5: '#354A7E',
  },
  red: '#C21A1A',
}

export const globalStyle = {
  global: {
    body: {
      bg: 'white',
      color: 'gray.g1',
    },
    h1: {
      fontWeight: 'extrabold',
      fontSize: '4xl',
    },
    h2: {
      fontWeight: 'regular',
      fontSize: '3xl',
    },
    h3: {
      fontWeight: 'bold',
      fontSize: '2xl',
    },
    h4: {
      fontWeight: 'bold',
      fontSize: 'xl',
    },
    b: {
      fontWeight: 'bold',
      fontSize: 'md',
    }
  },
}

export const textStyles = {
  small: {
    fontSize: 'sm',
  },
  smallBold: {
    fontSize: 'sm',
    fontWeight: 'bold',
  },
  smaller: {
    fontSize: 'xs',
  },
}

export const fonts = {
  body: 'Noto Sans KR, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
  heading:
    'Noto Sans KR, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
}

export const shadows = {
  sm: '0 2px 6px 0 rgba(0, 0, 0, 0.25)',
  md: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
}
