import { Select } from '@chakra-ui/react'

export type SelectOption = {
  value: string | number
  label: string
}

export function SelectForm({
  placeholder,
  value,
  setValue,
  option,
  isMobile,
}: {
  placeholder: string
  value: string
  setValue: (arg: string) => void
  option: SelectOption[] | number[]
  isMobile: boolean
}) {
  return (
    <Select
      placeholder={placeholder}
      bg={isMobile ? '#F7F7F7F7' : 'white'}
      m={0}
      p={0}
      value={value}
      variant={isMobile ? 'filled' : 'outline'}
      onChange={(e) => {
        setValue(e.target.value)
      }}
    >
      {option.map((op: SelectOption | number) => {
        let value, label
        if (typeof op !== 'number') {
          value = op.value
          label = op.label
        } else {
          value = op
          label = op
        }
        return (
          <option key={value} value={value}>
            {label}
          </option>
        )
      })}
    </Select>
  )
}
