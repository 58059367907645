import Diary from 'asset/diary.png'
import Service from 'asset/service.png'
import Test from 'asset/test.png'

import MobileDiary from 'asset/diary_mobile.png'
import MobileService from 'asset/service_mobile.png'
import MobileTest from 'asset/test_mobile.png'

export type ImageLayoutPage = '/service' | '/diary' | '/test'
export const ImageLayoutList = {
  '/service': {
    img: Service,
    title: '서비스 소개',
    desc: '정신건강 전문가에게 직접 케어받는 나의 마음, BRIGHT<br/>BRIGHT를 통해 여러분의 마음건강을 관리하세요.',
  },
  '/diary': {
    img: Diary,
    title: '마음일기',
    desc: '당신의 하루는 어땠나요?<br/>브라이트에 기록해 보세요.',
  },
  '/test': {
    img: Test,
    title: '마음검사',
    desc: '정신의학과에서 실제로 사용되는 검사로<br/>주기적으로 당신의 마음상태를 확인해보세요.',
  },
}
export const MobileImageLayoutList = {
  '/service': {
    img: MobileService,
    title: '내 손안의 마음건강 주치의,<br />BRIGHT',
  },
  '/diary': {
    img: MobileDiary,
    title: '오늘 당신의 하루는 어땠나요?<br />브라이트에 기록해 보세요.',
  },
  '/test': {
    img: MobileTest,
    title: '주기적으로 당신의 마음 상태를<br />확인해보세요.',
  },
}
