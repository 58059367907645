import { FilterOption, UserNotification } from 'model'
import { api } from '.'

class NotificationService {
  /**
    전체 알림 가져오기
  */
  async getAllNotification(filter: FilterOption, token: string) {
    const _filter: string = filter as string
    return await api.get<UserNotification[]>(
      `notifications?filter=${_filter}`,
      token
    )
  }

  /**
    전체/읽은 알림 삭제
  */
  async deleteAllNotification(read: boolean, token: string) {
    return await api.delete<boolean>('notifications', { read }, token)
  }

  /**
    안읽은 알림 갯수
  */
  async countUnreadNotifications(token: string) {
    return await api.get<number>('notifications/count', token)
  }

  /**
    알림 읽음 처리
  */
  async readNotification(id: number, token: string) {
    return await api.patch<boolean>(`notifications/${id}`, {}, token)
  }

  /**
    알림 삭제
  */
  async deleteNotification(id: number, token: string) {
    return await api.delete<boolean>(`notifications/${id}`, {}, token)
  }
}

export default new NotificationService()
