import { Box, Spacer, Text, Image, useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import Logo from 'asset/logo_Bright.png'
import { AuthService } from 'service'
import { ToastTheme } from 'component/common'

const sidebarList = [
  { label: '개인정보 관리', link: '/my/account' },
  { label: '이용권 관리', link: '/my/coupon' },
]
export function Sidebar() {
  const navigate = useNavigate()
  const toast = useToast()

  return (
    <Box h="100vh" bg="primary.m4" w="336px" minW="336px" px={16} py="9.5rem">
      <Image
        src={Logo}
        w="154px"
        cursor="pointer"
        onClick={() => {
          navigate('/')
        }}
      />
      <Spacer h={10} />
      {sidebarList.map((menu) => {
        return (
          <Text
            as="h3"
            key={menu.label}
            onClick={() => {
              navigate(menu.link)
            }}
            pl={2}
            pb={6}
            cursor="pointer"
          >
            {menu.label}
          </Text>
        )
      })}
      <Text
        color="primary.m2"
        pt={12}
        pl={2}
        as="h4"
        cursor="pointer"
        onClick={async () => {
          await AuthService.logout()
          toast({ description: '로그아웃 되었습니다', ...ToastTheme.success })
          navigate('/')
        }}
      >
        로그아웃
      </Text>
    </Box>
  )
}
