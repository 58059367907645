import {
  Box,
  Flex,
  IconButton,
  SimpleGrid,
  Spacer,
  Text,
  useDisclosure,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react'
import {
  Button,
  Calender,
  DiaryDetail,
  Modal,
  PageTitle,
  ToastTheme,
} from 'component'

import dayjs from 'dayjs'
import { useState } from 'react'
import {
  FaAngleDown,
  FaAngleLeft,
  FaAngleRight,
  FaAngleUp,
} from 'react-icons/fa'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { DiaryService } from 'service'

export default function DiaryDetailScreen() {
  const { id } = useParams()
  const toast = useToast()
  const [showCalendar, setShowCalendar] = useState<boolean>(false)
  const token = localStorage.getItem('access') ?? ''
  const navigate = useNavigate()
  const confirm = useDisclosure()
  const [prev, setPrev] = useState<boolean>(false)
  const [next, setNext] = useState<boolean>(false)

  const [isMobile] = useMediaQuery('(max-width: 480px)', {
    ssr: true,
    fallback: false,
  })

  const getDiaryDetail = useQuery(
    ['getDiaryDetail', id, token],
    async () => {
      return await DiaryService.getDiaryDetail(Number(id), token)
    },
    {
      refetchOnWindowFocus: false,
    }
  )

  useQuery(
    ['getNextDiary', id, token],
    async () => {
      return await DiaryService.getDiaryNext(Number(id), token)
    },
    {
      enabled:
        next && !getDiaryDetail.isLoading && getDiaryDetail.data !== null,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data) {
          navigate(`/diary/${data.id}`)
        } else {
          toast({
            description: '다음일기가 없습니다.',
            ...ToastTheme.error,
          })
        }
        setNext(false)
      },
      onError: () => {
        toast({
          description: '다음일기가 없습니다.',
          ...ToastTheme.error,
        })
      },
    }
  )

  useQuery(
    ['getPrevDiary', id, token],
    async () => {
      return await DiaryService.getDiaryPrev(Number(id), token)
    },
    {
      enabled:
        prev && !getDiaryDetail.isLoading && getDiaryDetail.data !== null,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data) {
          navigate(`/diary/${data.id}`)
        } else {
          toast({
            description: '이전일기가 없습니다.',
            ...ToastTheme.error,
          })
        }
        setPrev(false)
      },
      onError: () => {
        toast({
          description: '이전일기가 없습니다.',
          ...ToastTheme.error,
        })
      },
    }
  )

  const ConfirmModal = () => {
    return (
      <Modal title="오늘 마음일기 쓰러가기" state={confirm} isMobile={isMobile}>
        <Text>
          오늘 마음일기를 아직 쓰지 않으셨어요.
          <br />
          마음일기를 쓰러가시겠어요?
        </Text>
        <Spacer h={6} />
        <SimpleGrid columns={2} gap={2.5}>
          <Button
            style={{ borderRadius: 'xl' }}
            theme="gray"
            onClick={confirm.onClose}
          >
            취소
          </Button>
          <Button
            style={{ borderRadius: 'xl' }}
            onClick={() => {
              navigate('/diary/write')
            }}
          >
            확인
          </Button>
        </SimpleGrid>
      </Modal>
    )
  }

  const getToday = async () => {
    const today = dayjs(new Date()).format('YYYY-MM-DD')
    const diary = await DiaryService.getDiaryDetailByDate(today, token)
    if (diary) {
      navigate(`/diary/${diary.id}`)
    } else {
      confirm.onOpen()
    }
  }

  return (
    <Box w="100%" bg="gray.g4" minW={isMobile ? '' : '1280px'}>
      <PageTitle
        title="마음일기장"
        sx={isMobile ? { pb: '16px' } : { pb: 0 }}
        isMobile={isMobile}
      />
      <Box position="relative">
        {!isMobile && <Spacer h={12} />}
        <Flex
          as="section"
          px={isMobile ? '20px' : 44}
          align="center"
          justify="space-between"
        >
          <Flex align="center" gap={2}>
            <Text
              as="h4"
              display="inline-block"
              fontSize={isMobile ? '15px' : ''}
            >
              {!getDiaryDetail.isLoading &&
                dayjs(getDiaryDetail.data?.date).format('YYYY년 MM월')}
            </Text>
            <IconButton
              icon={<FaAngleDown />}
              borderRadius={999}
              fontSize="24px"
              aria-label="toggle calendar"
              bg="transparent"
              _active={{ transform: 'scale(0.98)' }}
              onClick={() => {
                setShowCalendar(true)
              }}
            />
          </Flex>
          <Flex align="center" gap={2}>
            <Text
              as="h4"
              display="inline-block"
              cursor="pointer"
              fontSize={isMobile ? '15px' : ''}
              onClick={() => {
                getToday()
              }}
            >
              오늘
            </Text>
            <IconButton
              icon={<FaAngleLeft />}
              borderRadius={999}
              fontSize="24px"
              aria-label="before one day"
              bg="transparent"
              _active={{ transform: 'scale(0.98)' }}
              onClick={() => {
                setPrev(true)
              }}
            />
            <IconButton
              icon={<FaAngleRight />}
              borderRadius={999}
              fontSize="24px"
              aria-label="after one day"
              bg="transparent"
              _active={{ transform: 'scale(0.98)' }}
              onClick={() => {
                setNext(true)
              }}
            />
          </Flex>
        </Flex>
        {showCalendar && (
          <Box
            position="absolute"
            bg="white"
            top="0"
            w="100%"
            px={isMobile ? 6 : 44}
            zIndex={1250}
            pb={isMobile ? 6 : 12}
            boxShadow="sm"
          >
            <Spacer h={12} />
            <Text as="h4" display="inline-block">
              {!getDiaryDetail.isLoading &&
                dayjs(getDiaryDetail.data?.date).format('YYYY년 MM월')}
            </Text>
            <IconButton
              icon={<FaAngleUp />}
              borderRadius={999}
              fontSize="24px"
              aria-label="toggle calendar"
              bg="transparent"
              _active={{ transform: 'scale(0.98)' }}
              onClick={() => {
                setShowCalendar(false)
              }}
            />
            <Calender setShowCalendar={setShowCalendar} />
          </Box>
        )}
      </Box>
      {!isMobile && <Spacer h={10} />}
      <Box as="section" px={isMobile ? '20px' : 44} py={isMobile ? '20px' : 0}>
        {!getDiaryDetail.isLoading && getDiaryDetail.data && (
          <DiaryDetail.Content
            diary={getDiaryDetail.data}
            isMobile={isMobile}
          />
        )}
        {isMobile && <Spacer h="20px" />}
        {!getDiaryDetail.isLoading &&
          getDiaryDetail.data &&
          getDiaryDetail.data?.answer !== null && (
            <DiaryDetail.Comment
              refetch={() => {
                getDiaryDetail.refetch()
              }}
              diary={getDiaryDetail.data}
              isMobile={isMobile}
            />
          )}
      </Box>
      <Spacer h={isMobile ? '40px' : '7.5rem'} />
      <ConfirmModal />
    </Box>
  )
}
