import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { useEffect, useState } from 'react'

import {
  Box,
  Flex,
  Icon,
  Spacer,
  Text,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react'
import { TestCard, TestDateCard } from 'component'
import { FaAngleRight } from 'react-icons/fa'

import { TestService } from 'service'
import { testExpiration } from 'store'
import { Test, TestResult, TestType } from 'model'

export default function TestScreen() {
  const token = localStorage.getItem('access') ?? ''
  const navigate = useNavigate()
  const setTestExp = useSetRecoilState(testExpiration)
  const [result, setResult] = useState([])
  const [recent, setRecent] = useState<
    Array<{
      id: number
      name: TestType
      test: TestResult[]
    }>
  >([])

  const [isMobile] = useMediaQuery('(max-width: 480px)', {
    ssr: true,
    fallback: false,
  })

  useEffect(() => {
    checkExp.mutate()
  }, [])

  const checkExp = useMutation(
    ['checkTestExp'],
    async () => {
      return await TestService.verify(token)
    },
    {
      onSuccess: (data: string[]) => {
        setTestExp(data)
      },
    }
  )
  useQuery(
    ['getFourResult'],
    async () => {
      return await TestService.getRecentFourResult(token)
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setResult(data)
      },
    }
  )
  useQuery(
    ['getAllRecentDate'],
    async () => {
      return await TestService.getAllRecentDate(token)
    },
    {
      onSuccess: (data) => {
        setRecent(data)
      },
    }
  )

  return (
    <Box minW={isMobile ? '' : '1280px'}>
      <Box
        as="section"
        bg="gray.g4"
        px={isMobile ? '20px' : 44}
        py={isMobile ? '32px' : '4.5rem'}
      >
        <Flex justify="space-between" align="center">
          <Text as="h3" fontSize={isMobile ? '18px' : ''}>
            나의 마음상태
          </Text>
          <Flex
            align="center"
            color={'primary.m2'}
            gap={2}
            cursor="pointer"
            onClick={() => {
              navigate('/test/my')
            }}
          >
            <Text as="b" fontSize={isMobile ? '13px' : ''}>
              자세히보기
            </Text>
            <Icon as={FaAngleRight} />
          </Flex>
        </Flex>
        <Spacer h={5} />
        <Flex
          gap={6}
          overflowX={isMobile ? 'auto' : 'hidden'}
          sx={
            isMobile
              ? {
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                  scrollbarWidth: 'none',
                  '-ms-overflow-style': 'none',
                }
              : {}
          }
        >
          {result.length === 0 && <Text>검사 기록이 없습니다.</Text>}
          {result.length !== 0 &&
            result.map((data: Test) => {
              return <TestCard key={data.id} data={data} isMobile={isMobile} />
            })}
        </Flex>
      </Box>
      <VStack
        gap={isMobile ? '12px' : 6}
        align="stretch"
        as="section"
        px={isMobile ? '20px' : 44}
        py={isMobile ? '32px' : '4.5rem'}
        pb={isMobile ? '60px' : '4.5rem'}
      >
        <Text as="h3" fontSize={isMobile ? '18px' : ''}>
          마음검사
        </Text>
        {recent.map((data) => {
          return <TestDateCard data={data} key={data.id} isMobile={isMobile} />
        })}
      </VStack>
    </Box>
  )
}
