/* eslint-disable multiline-ternary */
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'

import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Spacer,
  Text,
  Textarea,
  useDisclosure,
  useMediaQuery,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { EmojiTag, PageTitle, ToastTheme, DiaryModal } from 'component'
import { EmojiList } from 'data/emoji'
import { Tag } from 'model'
import { DiaryService, UserService } from 'service'

type Data = {
  message: string[]
  statusCode: number
}

export default function DiaryWriteScreen() {
  const toast = useToast()
  const navigate = useNavigate()
  const token = localStorage.getItem('access') ?? ''

  const yesterdayModal = useDisclosure()
  const confirmModal = useDisclosure()
  const [firstModal, setFirstModal] = useState('confirm')

  const [date, setDate] = useState<string>('')
  const [emotion, setEmotion] = useState<string>('')
  const [event, setEvent] = useState<string>('')
  const [think, setThink] = useState<string>('')
  const [free, setFree] = useState<string>('')
  const [tags, setTags] = useState<string[]>([])
  const [emoji, setEmoji] = useState<Tag[]>(EmojiList)

  const [isMobile] = useMediaQuery('(max-width: 480px)', {
    ssr: true,
    fallback: false,
  })

  const { data, isLoading } = useQuery(
    ['user'],
    async () => await UserService.getUser(token)
  )

  useEffect(() => {
    const now = dayjs(
      new Date().toLocaleString('en-US', { timeZone: 'Asia/Seoul' })
    )
    const nowH = now.get('h')
    setDate(now.format('YYYY-MM-DD'))
    if (nowH >= 0 && nowH <= 4) {
      setFirstModal('yesterday')
    }
  }, [])

  const setEmojiState = (id: number) => {
    const updateEmoji = [...emoji]
    const selectedEmoji = emoji[id - 1]
    updateEmoji[id - 1] = { ...selectedEmoji, status: !selectedEmoji.status }
    setEmoji([...updateEmoji])
    const selectedTag = updateEmoji.filter((x) => x.status).map((x) => x.name)
    setTags(selectedTag)
  }

  const write = useMutation(
    ['createDiary'],
    async () =>
      await DiaryService.create(
        { date, emotion, event, think, free, tags },
        token
      ),
    {
      onSuccess: () => {
        toast({
          description: '작성되었습니다.',
          ...ToastTheme.success,
        })
        navigate('/diary')
        confirmModal.onClose()
      },
      onError: (error: AxiosError) => {
        const message = (error.response?.data as Data).message
        toast({
          description: message,
          ...ToastTheme.error,
        })
        confirmModal.onClose()
      },
    }
  )

  return (
    <Box w="100%" minW={isMobile ? '' : '1280px'}>
      <PageTitle title="마음일기 쓰기" isMobile={isMobile} />
      <Box
        as="section"
        px={isMobile ? '20px' : 44}
        pt={isMobile ? 0 : 12}
        pb={isMobile ? '32px' : 16}
      >
        {!isLoading && (
          <Text as="h4" fontSize={isMobile ? '15px' : ''}>{`${
            !data ? '' : data.nick
          }님, 오늘 당신의 마음은 어땠나요?`}</Text>
        )}
        <Spacer h={isMobile ? '20px' : 7} />
        {isMobile ? (
          <Flex wrap="wrap" gap="6px">
            {emoji.map((item) => {
              return (
                <EmojiTag
                  key={item.id}
                  item={item}
                  isMobile={isMobile}
                  onClick={() => {
                    setEmojiState(item.id)
                  }}
                />
              )
            })}
          </Flex>
        ) : (
          <SimpleGrid columns={6} rowGap="2.5">
            {emoji.map((item) => {
              return (
                <EmojiTag
                  key={item.id}
                  item={item}
                  isMobile={isMobile}
                  onClick={() => {
                    setEmojiState(item.id)
                  }}
                />
              )
            })}
          </SimpleGrid>
        )}
      </Box>
      <VStack
        as="section"
        bg="primary.m4"
        px={isMobile ? '20px' : 44}
        pt={isMobile ? '32px' : 12}
        pb={isMobile ? '32px' : '4.5rem'}
        gap={10}
      >
        {[
          {
            id: 1,
            title: '오늘 하루 당신의 기분은 어땠나요?',
            value: emotion,
            setValue: setEmotion,
          },
          {
            id: 2,
            title: '당신의 기분에 영향을 준 일이 있었나요?',
            value: event,
            setValue: setEvent,
          },
          {
            id: 3,
            title: '그 일이 일어났을 때 어떤 생각이 들었나요?',
            value: think,
            setValue: setThink,
          },
          {
            id: 4,
            title: '자유롭게 이야기를 적어주세요.',
            value: free,
            setValue: setFree,
          },
        ].map((item) => {
          return (
            <Box key={item.id} w="100%">
              <Flex
                justify="space-between"
                align="center"
                mb={isMobile ? '16px' : 0}
              >
                <Text
                  as="h4"
                  fontSize={isMobile ? '15px' : ''}
                  mb={isMobile ? 0 : 4}
                >
                  {item.title}
                </Text>
                <Text
                  textStyle="smallBold"
                  as="a"
                  cursor="pointer"
                  fontSize={isMobile ? '13px' : ''}
                  color={isMobile ? '#949494' : ''}
                  _hover={{ textDecoration: 'underline' }}
                  onClick={() => {
                    item.setValue('')
                  }}
                >
                  전체 삭제
                </Text>
              </Flex>
              <Textarea
                placeholder="내용을 입력해주세요."
                value={item.value}
                onChange={(e) => {
                  e.preventDefault()
                  if (e.target.value.length > 300) {
                    return
                  }
                  item.setValue(e.target.value)
                }}
                bg="white"
                minH={isMobile ? '252px' : '120px'}
                resize="none"
                border="none"
                px={isMobile ? '16px' : 9}
                py={isMobile ? '16px' : 7}
                mb={isMobile ? '8px' : 0}
                fontSize={isMobile ? '15px' : ''}
              />
              {!isMobile && <Spacer h={5} />}
              <Text
                textStyle="small"
                textAlign="end"
                color={isMobile ? '#949494' : ''}
                fontSize={isMobile ? '13px' : ''}
              >
                {item.value.length} / 300
              </Text>
            </Box>
          )
        })}
        <Button
          w={isMobile ? '100%' : ''}
          h={isMobile ? '56px' : '40px'}
          py={isMobile ? 0 : 2}
          px={isMobile ? 0 : 7}
          mb={isMobile ? '40px' : ''}
          bg="primary.m2"
          color="white"
          fontSize={isMobile ? '15px' : ''}
          fontWeight={isMobile ? 'bold' : ''}
          borderRadius={isMobile ? '12px' : '999'}
          onClick={
            firstModal === 'yesterday'
              ? yesterdayModal.onOpen
              : confirmModal.onOpen
          }
        >
          작성 완료
        </Button>
      </VStack>
      <DiaryModal.Yesterday
        state={yesterdayModal}
        onYes={(yesterday: string) => {
          setDate(yesterday)
          yesterdayModal.onClose()
          confirmModal.onOpen()
        }}
        onNo={() => {
          yesterdayModal.onClose()
          confirmModal.onOpen()
        }}
        isMobile
      />
      <DiaryModal.Confirm
        state={confirmModal}
        onYes={() => {
          write.mutate()
        }}
        isMobile={isMobile}
      />
    </Box>
  )
}
