import { api } from '.'

class CouponService {
  async create(
    input: { name: string },
    token: string
  ) {
    return await api.post('coupons', input, token)
  }
}

export default new CouponService()
