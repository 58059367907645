import { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'

import {
  Box,
  Flex,
  Icon,
  Spacer,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { FaCircle, FaAngleRight } from 'react-icons/fa'
import { getTestState, verifyTest } from 'helpers'
import { TestResult, TestType } from 'model'
import { TestService } from 'service'
import { ResultGraph } from './ResultGraph'
import { ScoreBar } from './ScoreBar'
import { TestCategory } from 'data/testCategory'
import { CheckVerifyModal } from './CheckVerifyModal'

const defaultResult = {
  desc: '검사결과가 없습니다',
  state: {
    color: 'gray.g2',
    text: '없음',
  },
}

export function ResultCard({
  name,
  disabled,
  isMobile,
}: {
  name: TestType
  disabled?: boolean
  isMobile: boolean
}) {
  const token = localStorage.getItem('access') ?? ''
  const navigate = useNavigate()
  const enable = verifyTest(TestCategory[name].id)
  const state = useDisclosure()

  const [result, setResult] = useState<TestResult[]>([])
  const [resultState, setResultState] = useState<{
    desc: string
    state: { color: string; text: string }
  }>({ ...defaultResult })

  const goTest = () => {
    if (enable) {
      navigate(`/test/${name}`)
    } else {
      state.onOpen()
    }
  }

  useQuery(
    [`get${name}result`],
    async () => {
      return await TestService.getRecentResult(TestCategory[name].id, token)
    },
    {
      onSuccess: (data) => {
        setResult(data)
        if (data.length > 0) {
          setResultState(getTestState(name, data[data.length - 1].score))
        }
      },
    }
  )

  return (
    <Box
      as="section"
      p={isMobile ? '16px' : 24}
      boxShadow={isMobile ? '' : 'sm'}
      borderRadius="2xl"
      bg="white"
    >
      <CheckVerifyModal
        state={state}
        id={TestCategory[name].id}
        isMobile={isMobile}
      />
      <Flex justify="space-between" align="center">
        <Flex align="center" gap={4}>
          <Icon as={FaCircle} color={resultState.state.color} />
          <Text as="h3" fontSize={isMobile ? '18px' : ''}>
            {TestCategory[name].name}
          </Text>
        </Flex>
        {!disabled && (
          <Flex align="center" color={'primary.m2'} gap={2} onClick={goTest}>
            <Text as="b" cursor="pointer" fontSize={isMobile ? '13px' : ''}>
              검사 받기
            </Text>
            <Icon as={FaAngleRight} />
          </Flex>
        )}
      </Flex>
      <Spacer h={isMobile ? '15px' : 12} />
      <Flex
        align="center"
        gap={isMobile ? '24px' : '12'}
        flexDirection={isMobile ? 'column' : 'row'}
      >
        {!isMobile && (
          <ResultGraph result={result} name={name} isMobile={isMobile} />
        )}
        <VStack align="stretch" gap={isMobile ? '8px' : 2} flex="1" w="100%">
          <Flex>
            <Text as="b" w="110px" fontSize={isMobile ? '15px' : ''}>
              최근 검사일
            </Text>
            <Text fontSize={isMobile ? '15px' : ''}>
              {result.length > 0
                ? dayjs(result[result.length - 1].createdAt).format(
                    'YYYY년 M월 D일'
                  )
                : '없음'}
            </Text>
          </Flex>
          <Flex>
            <Text as="b" fontSize={isMobile ? '15px' : ''} w="110px">
              최근 검사결과
            </Text>
            <Text fontSize={isMobile ? '15px' : ''}>
              {result.length > 0
                ? `${Number(result[result.length - 1].score)} / ${
                    resultState.state.text
                  }`
                : '없음'}
            </Text>
          </Flex>
          <ScoreBar
            total={TestCategory[name].maxScore}
            score={result.length > 0 ? result[result.length - 1].score : 0}
            standard={TestCategory[name].standard}
            color={resultState.state.color}
          />
          <Text fontSize={isMobile ? '15px' : ''}>{resultState.desc}</Text>
        </VStack>
        {isMobile && (
          <ResultGraph result={result} name={name} isMobile={isMobile} />
        )}
      </Flex>
    </Box>
  )
}
