import { Flex, Text } from '@chakra-ui/react'

export default function NotFoundScreen() {
  return (
    <Flex
      w="100%"
      h="100vh"
      bg="primary.m4"
      justify="center"
      align="center"
      flexDirection="column"
    >
      <Text as="h1" color="primary.m2">
        404 Error
      </Text>
      <Text as="h3">존재하지 않는 페이지 입니다.</Text>
    </Flex>
  )
}
