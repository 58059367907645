import { api } from '.'

class TestService {
  /**
   * 마음검사 실행
   * @param input : {testCategoryId: test 고유 id, score: 전체 점수}
   * @param token
   * @returns boolean
   */
  async createTest(
    input: { testCategoryId: number; score: number },
    token: string
  ) {
    return await api.post('tests', input, token)
  }

  /**
   * 최근 4개 마음검사 결과 가져오기, 마음검사 대시보드에서 사용
   * @param token
   * @returns Test[]
   */
  async getRecentFourResult(token: string) {
    return await api.get('tests', token)
  }

  /**
   * 마음검사 카테고리에 해당하는 검사 기록 가져오기
   * @param id : 마음검사 고유 id
   * @param token
   * @returns Array<{score: number, createdAt: string}>
   */
  async getRecentResult(id: number, token: string) {
    return await api.get(`tests/category/${id}`, token)
  }

  /**
   * 마음검사 별 가장 최근 기록 가져오기
   * @param token
   * @returns
   */
  async getAllRecentDate(token: string) {
    return await api.get('tests/category/recent', token)
  }

  /**
   * 마음검사별로 검사 가능 여부 확인
   * @param token
   * @returns
   */
  async verify(token: string) {
    return await api.post('tests/verify', { }, token)
  }
}

export default new TestService()
