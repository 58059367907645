/* eslint-disable multiline-ternary */
import {
  Text,
  Box,
  Flex,
  Input,
  VStack,
  Spacer,
  Divider,
  Link,
  useToast,
  InputGroup,
  InputRightElement,
  IconButton,
  Image,
  Spinner,
  useMediaQuery,
} from '@chakra-ui/react'
import { Button, ToastTheme } from 'component'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Link as RLink, useNavigate } from 'react-router-dom'
import { AuthService, LocationService } from 'service'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa'
import Logo from 'asset/logo_Bright.png'
import { getCurrentAddress } from 'helpers'
import { MobileHeader } from 'component/mobile'

export default function LoginScreen() {
  const toast = useToast()
  const navigate = useNavigate()
  const [nick, setNick] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isMobile] = useMediaQuery('(max-width: 480px)', {
    ssr: true,
    fallback: false,
  })

  const login = useMutation(
    ['login'],
    async () => {
      return await AuthService.login({ nick, password })
    },
    {
      onSuccess: (data) => {
        localStorage.setItem('access', data.access)
        toast({
          description: '로그인 되었습니다.',
          ...ToastTheme.success,
        })
        navigate('/')
      },
      onError: () => {
        toast({
          description: '아이디와 비밀번호를 확인해주세요.',
          ...ToastTheme.error,
        })
      },
    }
  )

  const { data } = useQuery(
    ['location'],
    async () => await LocationService.get()
  )

  return (
    <>
      {isLoading && (
        <Flex
          position="absolute"
          h="100vh"
          w="100%"
          bgColor="rgba(0,0,0,0.3)"
          justify="center"
          align={isMobile ? 'start' : 'center'}
          pt={isMobile ? '40%' : ''}
          zIndex="1"
        >
          <Flex
            flexDirection="column"
            bgColor="primary.m2"
            borderRadius="8px"
            w="300px"
            h="150px"
            justifyContent="center"
            alignItems="center"
            zIndex={1}
            gap={6}
          >
            <Text textStyle="smallBold" color="white">
              잠시만 기다려주세요
            </Text>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="white"
              color="primary.m2"
              size="lg"
            />
          </Flex>
        </Flex>
      )}
      {isMobile ? (
        <>
          <MobileHeader />
          <Flex flexDir="column" px="20px" py="27px">
            <Text textAlign="left" fontSize="15px" fontWeight="bold" mb="12px">
              아이디
            </Text>
            <Input
              placeholder="아이디"
              value={nick}
              bg="#F7F7F7"
              variant="filled"
              mb="20px"
              onChange={(e) => {
                setNick(e.target.value)
              }}
            />
            <Text textAlign="left" fontSize="15px" fontWeight="bold" mb="12px">
              비밀번호
            </Text>
            <InputGroup>
              <Input
                placeholder="비밀번호"
                type={showPassword ? 'text' : 'password'}
                value={password}
                variant="filled"
                bg="#F7F7F7"
                mb="40px"
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    login.mutate()
                  }
                }}
              />
              <InputRightElement>
                <IconButton
                  bg="transparent"
                  _active={{ bg: 'transparent' }}
                  _hover={{ bg: 'transparent' }}
                  aria-label="show password btn"
                  icon={showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                  onClick={() => {
                    setShowPassword(!showPassword)
                  }}
                />
              </InputRightElement>
            </InputGroup>
            <Button
              style={{ h: '56px', borderRadius: 'lg', mb: '20px' }}
              onClick={async () => {
                setIsLoading(true)
                const location = await getCurrentAddress()
                setIsLoading(false)
                if (location === data?.name) {
                  login.mutate()
                } else {
                  toast({
                    description: `${
                      data
                        ? data?.name + '에서만 로그인이 가능합니다.'
                        : '해당지역에서는 로그인이 불가능합니다.'
                    }`,
                    ...ToastTheme.error,
                  })
                }
              }}
            >
              로그인
            </Button>
            <Flex
              justify="space-between"
              fontSize="13px"
              fontWeight="medium"
              color="#222222"
              px="40px"
            >
              <Link as={RLink} to="/find/id" style={{ textDecoration: 'none' }}>
                아이디 찾기
              </Link>
              <Link
                as={RLink}
                to="/find/password"
                style={{ textDecoration: 'none' }}
              >
                비밀번호 찾기
              </Link>
              <Text
                color="primary.m2"
                fontSize="13px"
                fontWeight="medium"
                as="button"
                onClick={async () => {
                  setIsLoading(true)
                  const location = await getCurrentAddress()
                  setIsLoading(false)
                  if (location === data?.name) {
                    navigate('/register')
                  } else {
                    toast({
                      description: `${
                        data
                          ? data?.name + '에서만 회원가입이 가능합니다.'
                          : '해당지역에서는 회원가입이 불가능합니다.'
                      }`,
                      ...ToastTheme.error,
                    })
                  }
                }}
              >
                회원가입
              </Text>
            </Flex>
          </Flex>
        </>
      ) : (
        <Flex
          flexDir="column"
          bg="primary.m2"
          h="100vh"
          w="100%"
          justify="center"
          align="center"
        >
          <Box
            as="section"
            bg="white"
            py={12}
            px={20}
            borderRadius="xl"
            textAlign="center"
          >
            <Image src={Logo} w="154px" marginX="auto" />
            <Spacer h={6} />
            <VStack gap={2}>
              <Input
                placeholder="아이디"
                w="350px"
                value={nick}
                onChange={(e) => {
                  setNick(e.target.value)
                }}
              />
              <InputGroup>
                <Input
                  placeholder="비밀번호"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value)
                  }}
                  onKeyDown={(e) => {
                    if (e.code === 'Enter') {
                      login.mutate()
                    }
                  }}
                />
                <InputRightElement>
                  <IconButton
                    bg="transparent"
                    _active={{ bg: 'transparent' }}
                    _hover={{ bg: 'transparent' }}
                    aria-label="show password btn"
                    icon={showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                    onClick={() => {
                      setShowPassword(!showPassword)
                    }}
                  />
                </InputRightElement>
              </InputGroup>
              <Button
                style={{ w: '100%', borderRadius: 'lg' }}
                onClick={async () => {
                  setIsLoading(true)
                  const location = await getCurrentAddress()
                  setIsLoading(false)
                  if (location === data?.name) {
                    login.mutate()
                  } else {
                    toast({
                      description: `${
                        data
                          ? data?.name + '에서만 로그인이 가능합니다.'
                          : '해당지역에서는 로그인이 불가능합니다.'
                      }`,
                      ...ToastTheme.error,
                    })
                  }
                }}
              >
                로그인
              </Button>
            </VStack>
            <Spacer h={10} />
            <Flex
              justify="space-around"
              textStyle="smallBold"
              color="gray.g2"
              h="5"
            >
              <Link as={RLink} to="/find/password">
                비밀번호 찾기
              </Link>
              <Divider orientation="vertical" color="gray.g3" />
              <Link as={RLink} to="/find/id">
                아이디 찾기
              </Link>
              <Divider orientation="vertical" color="gray.g3" />
              <Text
                textStyle="smallBold"
                color="gray.g2"
                as="button"
                _hover={{ textDecoration: 'underline' }}
                onClick={async () => {
                  setIsLoading(true)
                  const location = await getCurrentAddress()
                  setIsLoading(false)
                  if (location === data?.name) {
                    navigate('/register')
                  } else {
                    toast({
                      description: `${
                        data
                          ? data?.name + '에서만 회원가입이 가능합니다.'
                          : '해당지역에서는 회원가입이 불가능합니다.'
                      }`,
                      ...ToastTheme.error,
                    })
                  }
                }}
              >
                회원가입
              </Text>
            </Flex>
          </Box>
          <Text color="white" textStyle="smaller" mt={4}>
            COPYRIGHT ⓒ DOCTORPRESSO. ALL RIGHTS RESERVED
          </Text>
        </Flex>
      )}
    </>
  )
}
