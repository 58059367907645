import { Box, Flex, Spacer, Text, Button } from '@chakra-ui/react'
import { Diary } from 'model'
import { useMutation } from 'react-query'
import { DiaryService } from 'service'

export function DiaryComment({
  diary,
  refetch,
  isMobile,
}: {
  diary: Diary
  refetch: () => void
  isMobile: boolean
}) {
  const token = localStorage.getItem('access') ?? ''
  const likeAnswer = useMutation(
    ['likeAnswer'],
    async () => {
      await DiaryService.likeAnswer(diary.id, token)
    },
    {
      onSuccess: () => {
        refetch()
      },
    }
  )
  return (
    <Box
      borderRadius={isMobile ? '12px' : '0 0 30px 30px'}
      p={isMobile ? '16px' : 20}
      boxShadow={isMobile ? '' : 'sm'}
      bg="primary.m4"
    >
      <Flex
        gap={isMobile ? '4px' : 4}
        align={isMobile ? 'start' : 'center'}
        flexDirection={isMobile ? 'column' : 'row'}
      >
        <Text as="b" fontSize={isMobile ? '15px' : ''}>
          <Text as="span" color="primary.m2">
            닥터프레소
          </Text>
          님의 답변
        </Text>
        <Text textStyle="smallBold" fontSize={isMobile ? '13px' : ''}>
          {isMobile
            ? '서울대학교 의과대학 정신과 전문의'
            : '/ 서울대학교 의과대학 정신과 전문의'}
        </Text>
      </Flex>
      <Spacer h={isMobile ? '20px' : 8} />
      <Text>{diary.answer?.contents}</Text>
      <Spacer h={5} />
      <Flex w="100%" justify="flex-end">
        <Button
          h={isMobile ? '30px' : '40px'}
          py="2"
          px={isMobile ? '11px' : 7}
          mb={isMobile ? '16px' : ''}
          bg="primary.m2"
          color="white"
          fontSize={isMobile ? '15px' : ''}
          fontWeight={isMobile ? 'bold' : ''}
          borderRadius="999"
          onClick={() => {
            likeAnswer.mutate()
          }}
        >
          도움이 되었어요 ♥︎
        </Button>
      </Flex>
    </Box>
  )
}
