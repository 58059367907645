import { Box, Flex, Icon, Spacer, Text, useDisclosure } from '@chakra-ui/react'
import { TestCategory } from 'data/testCategory'
import dayjs from 'dayjs'
import { getTestState } from 'helpers'
import { Test, TestType } from 'model'
import { useEffect, useState } from 'react'
import { FaCircle } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { CheckVerifyModal } from './CheckVerifyModal'

const btnStyle = {
  enable: {
    color: 'white',
    bg: 'primary.m2',
  },
  disable: {
    color: 'gray.g2',
    bg: 'gray.g3',
  },
}

export function TestCard({
  data,
  isMobile,
}: {
  data: Test
  isMobile: boolean
}) {
  const name: TestType = data.testCategory.name
  const [color, setColor] = useState('gray.g2')
  // const enable = verifyTest(data.testCategoryId)
  const modal = useDisclosure()
  const navigate = useNavigate()

  const getColor = () => {
    const stateColor = getTestState(name, data.score).state.color
    setColor(stateColor)
  }

  const goTest = () => {
    // if (enable) {
    navigate(`/test/${data.testCategory.name}`)
    // } else {
    //   modal.onOpen()
    // }
  }
  useEffect(() => {
    getColor()
  }, [])

  return (
    <Box
      boxShadow="sm"
      pt={6}
      minW={isMobile ? '220px' : 28}
      w={isMobile ? '220px' : '25%'}
      bg="white"
      borderRadius="2xl"
    >
      <CheckVerifyModal
        id={data.testCategoryId}
        state={modal}
        isMobile={isMobile}
      />
      <Flex px={6} justify="space-between" align="center">
        <Flex direction="column">
          <Text as="b">{TestCategory[name].name} </Text>
          <Text textStyle="smaller">
            {dayjs(data.createdAt).format('M월 D일')}
          </Text>
        </Flex>
        <Icon as={FaCircle} w={5} h={5} color={color} mb="5px" />
      </Flex>
      <Spacer h={6} />
      <Text
        as="h4"
        px={6}
        pb={6}
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {data.score}
      </Text>
      <Text
        p={3}
        as="b"
        display="block"
        borderBottomLeftRadius="2xl"
        borderBottomRightRadius="2xl"
        textAlign="center"
        w="100%"
        bg={'gray.g3'}
        onClick={goTest}
        cursor="pointer"
        color="gray.g2"
        sx={{ ...btnStyle.enable }}
      >
        {'검사받기'}
      </Text>
    </Box>
  )
}
