import { Question } from 'model'

const answer = [
  { score: 0, desc: '전혀 방해받지 않았다.' },
  { score: 1, desc: '며칠 동안 방해받았다.' },
  { score: 2, desc: '7일 이상 방해받았다.' },
  { score: 3, desc: '거의 매일 방해받았다.' },
]

export const PHQ: Question[] = [
  {
    id: 1,
    answer,
    question: '일 또는 여가 활동을 하는 데 흥미나 즐거움을 느끼지 못함',
  },
  { id: 2, answer, question: '기분이 가라앉거나, 우울하거나, 희망이 없음' },
  {
    id: 3,
    answer,
    question: '잠이 들거나 계속 잠을 자는 것이 어려움, 또는 잠이 너무 많이 옴',
  },
  { id: 4, answer, question: '피곤하다고 느끼거나 기운이 거의 없음' },
  { id: 5, answer, question: '입맛이 없거나 과식을 함' },
  {
    id: 6,
    answer,
    question:
      '자신을 부정적으로 봄 - 혹은 자신이 실패자라고 느끼거나 자신 또는 가족을 실망시킴',
  },
  {
    id: 7,
    answer,
    question:
      '신문을 읽거나 텔레비전을 보는 것과 같은 일에 집중하는 것이 어려움',
  },
  {
    id: 8,
    answer,
    question:
      '다른 사람들이 주목할 정도로 너무 느리게 움직이거나 말을 함. 또는 반대로 평상시보다 많이 움직여서, 너무 안절부절 못하거나 들떠 있음',
  },
  {
    id: 9,
    answer,
    question:
      '자신이 죽는 것이 더 낫다고 생각하거나 어떤 식으로든 자신을 해칠 것 같이 생각함.',
  },
]
