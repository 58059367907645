import {
  Flex,
  Text,
  Image,
  Icon,
  useMediaQuery,
  IconButton,
} from '@chakra-ui/react'
import Fin from 'asset/register_fin.png'
import { Button } from 'component'
import { FaAngleRight as RightIcon } from 'react-icons/fa'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { UserService } from 'service'
import { MdClose } from 'react-icons/md'
import Logo from 'asset/logo_Bright.png'

export default function RegisterFinScreen() {
  const token = localStorage.getItem('access') ?? ''
  const navigate = useNavigate()
  const { data, isLoading } = useQuery(
    ['user'],
    async () => await UserService.getUser(token)
  )
  const [isMobile] = useMediaQuery('(max-width: 480px)', {
    ssr: true,
    fallback: false,
  })
  return (
    <Flex
      align="center"
      justify="center"
      w="100%"
      h="100vh"
      bg="primary.m4"
      flexDir="column"
      textAlign="center"
      px={isMobile ? '20px' : ''}
    >
      {isMobile && (
        <IconButton
          position="absolute"
          top="20px"
          right="20px"
          icon={<MdClose />}
          fontSize="24px"
          aria-label="back button"
          bg="transparent"
          _active={{ transform: 'scale(0.98)' }}
          _hover={{ bg: 'transparent' }}
          onClick={() => {
            navigate('/')
          }}
        />
      )}
      <Image src={Logo} w={isMobile ? '110.22px' : '154px'} mb={5} />
      <Text as="h4" fontSize={isMobile ? '15px' : '20px'} mb={12}>
        <Text as="span" color="primary.m2">
          {!isLoading && data?.nick}
        </Text>
        님, 가입을 환영합니다. <br />
        오늘부터 브라이트와 함께 마음 건강을 챙겨보세요!
      </Text>
      <Image src={Fin} w={isMobile ? '50%' : '25%'} h="auto" mb={12} />
      <Button
        style={
          isMobile
            ? { w: '100%', borderRadius: 'lg', mb: '20px', fontWeight: 'bold' }
            : { w: '350px', borderRadius: 'lg', mb: '20px' }
        }
        onClick={() => {
          navigate('/')
        }}
      >
        시작하기
      </Button>
      <Flex align="center" justify="space-between" gap={15}>
        <Text as="b" fontSize={isMobile ? '13px' : '16px'}>
          가지고 계신 이용권이 있다면?
        </Text>
        <Flex align="center" color="primary.m2">
          <Text
            as="b"
            textDecoration={isMobile ? 'underline' : 'none'}
            fontSize={isMobile ? '13px' : '16px'}
            cursor="pointer"
            onClick={() => {
              navigate('/my/coupon')
            }}
          >
            이용권 등록하기
          </Text>
          {!isMobile && <Icon as={RightIcon} />}
        </Flex>
      </Flex>
    </Flex>
  )
}
