import {
  Input,
  Spacer,
  Text,
  VStack,
  Link,
  Flex,
  useToast,
} from '@chakra-ui/react'
import { Button, ToastTheme } from 'component'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { Link as RLink, useLocation } from 'react-router-dom'
import { AuthService } from 'service'

type Route = {
  name: string
  url: string
}
const route = {
  id: {
    name: '비밀번호',
    url: '/find/password',
  },
  password: {
    name: '아이디',
    url: '/find/id',
  },
}
export function EmailInput({
  setEmail,
  goNextStep,
  isMobile,
}: {
  setEmail: (arg: string) => void
  goNextStep: () => void
  isMobile: boolean
}) {
  const toast = useToast()
  const [value, setValue] = useState<string>('')
  const [type, setType] = useState<Route>(route.id)
  const path = useLocation().pathname

  useEffect(() => {
    if (path === '/find/id') {
      setType(route.id)
    } else {
      setType(route.password)
    }
  }, [path])

  const checkEmailValid = useMutation(
    ['checkEmailValid'],
    async () => {
      try {
        return await AuthService.checkEmailValid({ email: value })
      } catch (err) {
        toast({
          description:
            '해당 메일로 가입된 계정이 없습니다. 메일을 다시 확인해주세요.',
          ...ToastTheme.error,
        })
        throw new Error('no email')
      }
    },
    {
      onSuccess: (data) => {
        if (data) {
          sendAuthEmail.mutate()
        }
      },
    }
  )

  const sendAuthEmail = useMutation(
    ['sendAuthEmail'],
    async () => {
      return await AuthService.sendAuthEmail({ email: value })
    },
    {
      onSuccess: () => {
        toast({
          description: '인증번호가 전송되었습니다.',
          ...ToastTheme.success,
        })
        setEmail(value)
        goNextStep()
      },
    }
  )

  return (
    <>
      <Text textStyle="smallBold" mb={isMobile ? '12px' : ''}>
        회원정보에 등록된 이메일을 입력해주세요.
      </Text>
      {!isMobile && <Spacer h={6} />}
      <VStack gap={isMobile ? '40px' : 2}>
        <Input
          placeholder="이메일"
          h={isMobile ? '50px' : '40px'}
          w={isMobile ? '100%' : '350px'}
          type="email"
          value={value}
          bg={isMobile ? '#F7F7F7' : 'white'}
          variant={isMobile ? 'filled' : 'outline'}
          onChange={(e) => {
            e.preventDefault()
            setValue(e.target.value)
          }}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              checkEmailValid.mutate()
            }
          }}
        />
        <Button
          style={
            isMobile
              ? {
                  w: '100%',
                  borderRadius: 'lg',
                  fontWeight: 'bold',
                  h: '56px',
                }
              : { w: '100%', borderRadius: 'lg' }
          }
          onClick={() => {
            checkEmailValid.mutate()
          }}
        >
          다음
        </Button>
      </VStack>
      {!isMobile && <Spacer h={10} />}
      <Flex
        justify={isMobile ? 'space-between' : 'center'}
        gap={2}
        textStyle={isMobile ? 'small' : 'smallBold'}
        mt={isMobile ? '20px' : ''}
      >
        <Text color={isMobile ? '#222222' : 'gray.g2'}>
          {type.name}가 기억나지 않는다면?
        </Text>
        <Link as={RLink} to={type.url} color="primary.m2" fontWeight="medium">
          {type.name} 찾기
        </Link>
      </Flex>
    </>
  )
}
