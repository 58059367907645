import { NotificationCategory } from 'model'

export const getCategory = (category: NotificationCategory) => {
  const categories = {
    diary: '마음일기 알림',
    test: '마음검사 알림',
    notice: '공지',
    etc: '기타',
  }
  return categories[category]
}
