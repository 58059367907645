/* eslint-disable multiline-ternary */
import { Box, Flex, Link, Spacer, Text } from '@chakra-ui/react'
import { Button } from 'component'
import { Link as RLink } from 'react-router-dom'

export function FindPwFin({ isMobile }: { isMobile: boolean }) {
  return (
    <>
      {isMobile ? (
        <Flex
          w="100%"
          h="100%"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            flex="1"
            flexDirection="column"
          >
            <Text textAlign="center" fontSize="15px" fontWeight="bold">
              비밀번호 재설정이 완료되었습니다.
              <br />
              새로운 비밀번호로 다시 로그인해주세요.
            </Text>
          </Flex>
          <Link as={RLink} to="/login" mb="40px">
            <Button
              style={{ borderRadius: 'xl', width: '100%', fontWeight: 'bold' }}
            >
              로그인
            </Button>
          </Link>
        </Flex>
      ) : (
        <Box w="350px">
          <Text>
            비밀번호 재설정이 완료되었습니다. 새로운 비밀번호로 다시
            로그인해주세요.
          </Text>
          <Spacer h={6} />
          <Link as={RLink} to="/login">
            <Button style={{ borderRadius: 'xl', w: '100%' }}>로그인</Button>
          </Link>
        </Box>
      )}
    </>
  )
}
