import { DiaryContent } from './diaryContent/DiaryContent'
import { DiaryComment } from './diaryContent/DiaryComment'
import { ConfirmModal } from './ConfirmModal'
import { YesterdayModal } from './YesterdayModal'

export * from './DiaryCard'
export * from './EmojiTag'
export const DiaryDetail = {
  Content: DiaryContent,
  Comment: DiaryComment,
}
export const DiaryModal = {
  Confirm: ConfirmModal,
  Yesterday: YesterdayModal,
}
