import dayjs from 'dayjs'
import { useState } from 'react'
import Calendar from 'react-calendar'
import './calendar.css'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'
import { FaAngleRight, FaAngleLeft } from 'react-icons/fa'

import { DiaryService } from 'service'
import { ToastTheme } from '../toast'
import { Diary } from 'model'

type renderDate = {
  date: string
  prev: number
  next: number
  new: boolean
}

export function Calender({
  setShowCalendar,
}: {
  setShowCalendar?: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const token = localStorage.getItem('access') ?? ''
  const toast = useToast()
  const navigate = useNavigate()
  const [renderDateList, setRenderDateList] = useState<renderDate[]>([])
  const [year, setYear] = useState(dayjs().year())
  const [month, setMonth] = useState(dayjs().month() + 1)
  const [getDiary, setGetDiary] = useState<boolean>(true)

  const getDiaryByDate = useQuery(
    ['getDiaryByDate', year, month],
    async () => {
      return await DiaryService.getCalendar(year, month, token)
    },
    {
      refetchOnWindowFocus: false,
      enabled: getDiary,
      onSuccess: (data) => {
        setRenderDataSet(data)
        setGetDiary(false)
      },
    }
  )

  const setRenderDataSet = (data: Diary[]) => {
    const set: renderDate[] = []

    data.forEach((d) => {
      let newData: renderDate = {
        date: dayjs(d.date).format('YYYY-MM-DD'),
        prev: 0,
        next: 0,
        new: !d.read && d.answer != null,
      }
      const prev = dayjs(d.date).subtract(1, 'day').format('YYYY-MM-DD')
      const next = dayjs(d.date).add(1, 'day').format('YYYY-MM-DD')
      if (
        data.findIndex((d) => dayjs(d.date).format('YYYY-MM-DD') === prev) !==
        -1
      ) {
        newData = { ...newData, prev: 1 }
      }
      if (
        dayjs().format('YYYY-MM-DD') !== next &&
        data.findIndex((d) => dayjs(d.date).format('YYYY-MM-DD') === next) !==
          -1
      ) {
        newData = { ...newData, next: 1 }
      }
      set.push(newData)
    })
    setRenderDateList(set)
  }

  const getDiaryDetail = async (date: string) => {
    const diary = await DiaryService.getDiaryDetailByDate(date, token)
    if (!diary) {
      toast({
        description: '해당날짜의 일기가 존재하지 않습니다.',
        ...ToastTheme.error,
      })
    } else {
      navigate(`/diary/${diary.id}`)
      if (setShowCalendar) {
        setShowCalendar(false)
      }
    }
  }

  return (
    <>
      <Calendar
        calendarType="US"
        className="react-calendar"
        next2Label={null}
        prev2Label={null}
        locale="ko"
        nextLabel={<FaAngleRight />}
        prevLabel={<FaAngleLeft />}
        showNeighboringMonth={false}
        formatDay={(_, date) => dayjs(date).format('D')}
        selectRange={true}
        onClickDay={(a) => {
          const date = dayjs(a).format('YYYY-MM-DD').toString()
          getDiaryDetail(date)
        }}
        onActiveStartDateChange={({ activeStartDate }) => {
          setYear(dayjs(activeStartDate).year())
          setMonth(dayjs(activeStartDate).month() + 1)
          setGetDiary(true)
        }}
        tileClassName={({ date, view }) => {
          const className = []
          if (getDiaryByDate.isLoading) {
            return 'none'
          }
          const idx =
            !getDiaryByDate.isLoading &&
            renderDateList.findIndex(
              (x) => x.date === dayjs(date).format('YYYY-MM-DD')
            )
          if (idx === -1) {
            return 'none'
          } else {
            if (renderDateList[idx].new) {
              className.push('new')
            }
            if (renderDateList[idx].date === dayjs().format('YYYY-MM-DD')) {
              return [...className, 'dot']
            }
            if (
              renderDateList[idx].next === 0 &&
              renderDateList[idx].prev === 0
            ) {
              return [...className, 'dot']
            } else if (
              renderDateList[idx].next === 1 &&
              renderDateList[idx].prev === 0
            ) {
              return [...className, 'prev']
            } else if (
              renderDateList[idx].next === 1 &&
              renderDateList[idx].prev === 1
            ) {
              return [...className, 'mid']
            } else {
              return [...className, 'next']
            }
          }
        }}
      />
    </>
  )
}
