import { Flex, Image, Text } from '@chakra-ui/react'
import { Tag } from 'model'

const basicStyle = {
  bg: 'white',
  borderColor: 'gray.g3',
}
const activeStyle = {
  bg: 'primary.m4',
  color: 'primary.m2',
  borderColor: 'primary.m2',
}

export function EmojiTag({
  item,
  onClick,
  isMobile,
}: {
  item: Tag
  onClick?: () => void
  isMobile: boolean
}) {
  return (
    <Flex
      onClick={onClick}
      cursor="pointer"
      align="center"
      py={isMobile ? '6px' : 2.5}
      px={isMobile ? '12px' : 4}
      gap={2}
      w={isMobile ? 'auto' : '10rem'}
      borderRadius="full"
      borderWidth="2px"
      sx={item.status ? activeStyle : basicStyle}
      _hover={activeStyle}
      _active={{
        transform: 'scale(0.98)',
      }}
    >
      <Image src={item.emoji} h="20px" />
      <Text fontSize={isMobile ? '15px' : ''} whiteSpace="nowrap">
        {item.name}
      </Text>
    </Flex>
  )
}
