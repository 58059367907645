/* eslint-disable multiline-ternary */
import { Box, Divider, Flex, SimpleGrid, Spacer, Text } from '@chakra-ui/react'
import { EmojiList } from 'data/emoji'
import dayjs from 'dayjs'
import { Diary } from 'model'
import { EmojiTag } from '../EmojiTag'

export function DiaryContent({
  diary,
  isMobile,
}: {
  diary?: Diary
  isMobile: boolean
}) {
  const answer = diary?.answer === null
  return (
    <Box
      borderRadius={answer ? '30px' : isMobile ? '12px' : '30px 30px 0 0'}
      p={isMobile ? '16px' : 20}
      boxShadow={isMobile ? '' : 'sm'}
      bg="white"
    >
      <Flex gap={4}>
        {!isMobile && (
          <Text as="h3" color="primary.m2">
            Date.{' '}
          </Text>
        )}
        <Text
          as="h3"
          fontSize={isMobile ? '18px' : ''}
          pb={isMobile ? '12px' : ''}
        >
          {dayjs(diary?.date).format('YYYY년 MM월 DD일')}
        </Text>
      </Flex>
      {!isMobile && <Spacer h="3.125rem" />}
      {isMobile ? (
        <Flex wrap="wrap" gap="6px">
          {diary?.diaryTag.map((tag) => {
            return (
              <EmojiTag
                item={{
                  ...tag.tag,
                  status: true,
                  emoji: EmojiList.at(tag.tag.id - 1)?.emoji ?? '',
                }}
                key={tag.tag.id}
                isMobile={isMobile}
              />
            )
          })}
        </Flex>
      ) : (
        <SimpleGrid columns={{ base: 4, '2xl': 5 }} gap={2}>
          {diary?.diaryTag.map((tag) => {
            return (
              <EmojiTag
                item={{
                  ...tag.tag,
                  status: true,
                  emoji: EmojiList.at(tag.tag.id - 1)?.emoji ?? '',
                }}
                key={tag.tag.id}
                isMobile={isMobile}
              />
            )
          })}
        </SimpleGrid>
      )}
      <Spacer h={7} />
      {!isMobile && <Divider borderColor="gray.g3" borderWidth="3px" />}
      {[
        { title: '나의 기분', content: diary?.emotion },
        { title: '나의 사건', content: diary?.event },
        { title: '나의 생각', content: diary?.think },
        { title: '나의 이야기', content: diary?.free },
      ].map((item) => {
        return (
          <Box key={item.title} mt={3} mb={7}>
            <Text as="b" fontSize={isMobile ? '15px' : ''}>
              {item.title}
            </Text>
            <Divider borderColor="gray.g3" my={3} />
            <Text fontSize={isMobile ? '15px' : ''}>{item.content}</Text>
          </Box>
        )
      })}
    </Box>
  )
}
