import { Box, BoxProps, Divider, Text } from '@chakra-ui/react'

export function PageTitle({
  title,
  sx,
  isMobile,
}: {
  title: string
  sx?: BoxProps
  isMobile: boolean
}) {
  return (
    <Box
      w="100%"
      pt={isMobile ? '32px' : 16}
      pb={isMobile ? '16px' : 12}
      sx={{ ...sx }}
    >
      <Text
        as="h4"
        fontSize={isMobile ? '18px' : ''}
        mb={isMobile ? 0 : 4}
        px={isMobile ? '20px' : 44}
      >
        {title}
      </Text>
      {!isMobile && <Divider w="100%" borderColor="gray.g3" />}
    </Box>
  )
}
