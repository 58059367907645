import { VerifyCode } from './code'
import { EmailInput } from './email'
import { FindId } from './id/findId'
import { ResetPw } from './pw/reset'
import { FindPwFin } from './pw/fin'

export const FindIdContent = {
  EmailInput,
  VerifyCode,
  FindId,
}

export const FindPwContent = {
  EmailInput,
  VerifyCode,
  ResetPw,
  FindPwFin
}
