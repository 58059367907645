/* eslint-disable multiline-ternary */
import { Text, Box, Flex, Spacer, Image, useMediaQuery } from '@chakra-ui/react'
import { FindPwContent } from 'component'
import { useState } from 'react'
import Logo from 'asset/logo_Bright.png'
import { MobileHeader } from 'component/mobile'

export default function FindPasswordScreen() {
  const [step, setStep] = useState<number>(0)
  const [email, setEmail] = useState<string>('')
  const [isMobile] = useMediaQuery('(max-width: 480px)', {
    ssr: true,
    fallback: false,
  })

  const goNextStep = (step: number) => {
    setStep(step)
  }
  return (
    <>
      {isMobile ? (
        <>
          <MobileHeader />
          <Flex flexDir="column" px="20px" py="27px">
            {step === 0 && (
              <FindPwContent.EmailInput
                setEmail={(e: string) => {
                  setEmail(e)
                }}
                goNextStep={() => {
                  goNextStep(1)
                }}
                isMobile={isMobile}
              />
            )}
            {step === 1 && (
              <FindPwContent.VerifyCode
                email={email}
                goNextStep={() => {
                  goNextStep(2)
                }}
                isMobile={isMobile}
              />
            )}
            {step === 2 && (
              <FindPwContent.ResetPw
                email={email}
                goNextStep={() => {
                  goNextStep(3)
                }}
                isMobile={isMobile}
              />
            )}
            {step === 3 && <FindPwContent.FindPwFin isMobile={isMobile} />}
          </Flex>
        </>
      ) : (
        <Flex
          flexDir="column"
          bg="primary.m2"
          h="100vh"
          w="100%"
          justify="center"
          align="center"
        >
          <Box
            as="section"
            bg="white"
            py={12}
            px={20}
            borderRadius="xl"
            textAlign="center"
          >
            <Image src={Logo} w="154px" marginX="auto" />
            <Spacer h={4} />
            <Text as="h4">
              {step === 0 || step === 1 ? '비밀번호 찾기' : '비밀번호 재설정'}
            </Text>
            {step === 0 && (
              <FindPwContent.EmailInput
                setEmail={(e: string) => {
                  setEmail(e)
                }}
                goNextStep={() => {
                  goNextStep(1)
                }}
                isMobile={isMobile}
              />
            )}
            {step === 1 && (
              <FindPwContent.VerifyCode
                email={email}
                goNextStep={() => {
                  goNextStep(2)
                }}
                isMobile={isMobile}
              />
            )}
            {step === 2 && (
              <FindPwContent.ResetPw
                email={email}
                goNextStep={() => {
                  goNextStep(3)
                }}
                isMobile={isMobile}
              />
            )}
            {step === 3 && <FindPwContent.FindPwFin isMobile={isMobile} />}
          </Box>
        </Flex>
      )}
    </>
  )
}
