import { Box, BoxProps, useMediaQuery } from '@chakra-ui/react'
import { ReactNode } from 'react'

const themeStyle = {
  default: {
    bg: 'primary.m2',
    activeBg: 'primary.m5',
  },
  gray: {
    bg: 'gray.g2',
    activeBg: 'gray.g1',
  },
}

export function Button({
  children,
  onClick,
  theme,
  style,
  disabled,
}: {
  children: ReactNode
  onClick?: () => void
  theme?: 'default' | 'gray'
  style?: BoxProps
  disabled?: boolean
}) {
  const [isMobile] = useMediaQuery('(max-width: 480px)', {
    ssr: true,
    fallback: false,
  })
  const colorTheme: 'default' | 'gray' = theme ?? 'default'
  return (
    <Box
      as="button"
      py="2"
      px="7"
      borderRadius="999"
      color="white"
      h={isMobile ? '50px' : '40px'}
      cursor={disabled ? 'not-allowed' : 'cursor'}
      bg={disabled ? themeStyle.gray.bg : themeStyle[colorTheme].bg}
      _hover={disabled ? {} : { bg: themeStyle[colorTheme].activeBg }}
      _active={
        disabled
          ? {}
          : {
              bg: themeStyle[colorTheme].activeBg,
              transform: 'scale(0.98)',
              borderColor: 'transparent',
            }
      }
      _focus={
        disabled
          ? {}
          : {
              borderColor: 'transparent',
              boxShadow: 'none',
            }
      }
      onClick={onClick}
      sx={style}
      disabled={disabled}
    >
      {children}
    </Box>
  )
}

export function OutlineButton({
  children,
  onClick,
  status,
}: {
  children: ReactNode
  onClick?: () => void
  status?: boolean
}) {
  const basicStyle = {
    bg: 'white',
    borderColor: 'gray.g3',
  }
  const activeStyle = {
    bg: 'primary.m4',
    color: 'primary.m2',
    borderColor: 'primary.m2',
  }

  return (
    <Box
      onClick={onClick}
      cursor="pointer"
      display="inline-block"
      py={2.5}
      px={8}
      borderRadius="full"
      borderWidth="2px"
      sx={status ? activeStyle : basicStyle}
      _hover={activeStyle}
      _active={{
        transform: 'scale(0.98)',
      }}
    >
      {children}
    </Box>
  )
}
