import { Box, Flex, Text } from '@chakra-ui/react'

const backBoxStyle = {
  bg: 'gray.g4',
  borderWidth: '1px',
  borderRightWidth: 0,
  h: '20px',
}

export function ScoreBar({
  score,
  total,
  standard,
  color,
}: {
  score: number
  total: number
  standard: number
  color: string
}) {
  const l = Number((total / standard).toFixed())
  const boxNum = Array.from({ length: l }, () => 0)
  const activeNum = Array.from(
    { length: Number((score / standard).toFixed()) },
    () => 0
  )
  return (
    <Box w="100%" position="relative" display="block" h="40px">
      <Flex position="absolute" w="100%">
        <Box sx={{ ...backBoxStyle }} borderRadius="999px 0 0 999px" w="20px" />
        {boxNum.map((_, idx) => {
          return (
            <Flex direction="column" key={idx} w={`${100 / l}%`}>
              <Box sx={{ ...backBoxStyle }} />
              <Text
                textStyle="smallBold"
                color="gray.g2"
                position="relative"
                left="-2px"
              >
                {idx * standard}
              </Text>
            </Flex>
          )
        })}
        <Flex direction="column">
          <Box
            borderRadius="0 999px 999px 0"
            w="20px"
            sx={{ ...backBoxStyle }}
          />
          <Text
            textStyle="smallBold"
            color="gray.g2"
            position="relative"
            left="-3px"
          >
            {total}
          </Text>
        </Flex>
      </Flex>
      <Flex position="absolute" w="100%">
        <Box
          sx={{ ...backBoxStyle, bg: color }}
          borderRadius="999px 0 0 999px"
          w="20px"
        />
        {activeNum.map((_, idx: number) => {
          return (
            <Box
              key={`active box ${idx}`}
              w={`${100 / l}%`}
              sx={{ ...backBoxStyle, bg: color }}
            />
          )
        })}
        <Box
          borderRadius="0 999px 999px 0"
          w={
            score !== 0 && score % standard === 0
              ? '20px'
              : `${(100 / l) * ((score % standard) / standard)}%`
          }
          sx={{ ...backBoxStyle, bg: color }}
        />
      </Flex>
    </Box>
  )
}
