import { Box, Input, Spacer, Text, useToast, VStack } from '@chakra-ui/react'
import { Button, ToastTheme } from 'component'
import { validPassword } from 'helpers'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { AuthService } from 'service'

export function ResetPw({
  email,
  goNextStep,
  isMobile,
}: {
  email: string
  goNextStep: () => void
  isMobile: boolean
}) {
  const toast = useToast()
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')

  const changePassword = useMutation(
    ['changePassword'],
    async () => {
      try {
        await AuthService.resetPassword({
          email,
          password,
        })
      } catch (err: any) {
        const error: Error = err.response.data as Error
        toast({
          description: error.message,
          ...ToastTheme.error,
        })
        throw new Error('error')
      }
    },
    {
      onSuccess: () => {
        toast({
          description: '비밀번호가 변경되었습니다.',
          ...ToastTheme.success,
        })
        goNextStep()
      },
    }
  )
  return (
    <Box w={isMobile ? '100%' : '350px'}>
      <Text textStyle="smallBold" mb={isMobile ? '12px' : ''}>
        새로운 비밀번호를 입력해 주세요.
      </Text>
      {!isMobile && <Spacer h={6} />}

      <VStack gap={2} align="stretch">
        <Box>
          <Input
            h={isMobile ? '50px' : '40px'}
            w={isMobile ? '100%' : '350px'}
            bg={isMobile ? '#F7F7F7' : 'white'}
            variant={isMobile ? 'filled' : 'outline'}
            placeholder="새로운 비밀번호"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
            type="password"
          />
          <Text color="red" display="block" fontSize="sm">
            {password.length === 0 || validPassword(password)
              ? ''
              : '8자 이상, 숫자, 문자를 포함해주세요'}
          </Text>
        </Box>
        <Box>
          <Input
            h={isMobile ? '50px' : '40px'}
            w={isMobile ? '100%' : '350px'}
            bg={isMobile ? '#F7F7F7' : 'white'}
            variant={isMobile ? 'filled' : 'outline'}
            placeholder="비밀번호 확인"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value)
            }}
            type="password"
          />
          <Text color="red" display="block" fontSize="sm">
            {password.length > 0 &&
            confirmPassword.length > 0 &&
            password === confirmPassword
              ? ''
              : '입력한 비밀번호와 동일하게 입력해주세요'}
          </Text>
        </Box>
        <Button
          style={
            isMobile
              ? {
                  w: '100%',
                  borderRadius: 'lg',
                  fontWeight: 'bold',
                  h: '56px',
                }
              : { w: '100%', borderRadius: 'lg' }
          }
          disabled={
            password.length === 0 ||
            confirmPassword.length === 0 ||
            password !== confirmPassword
          }
          onClick={() => {
            changePassword.mutate()
          }}
        >
          다음
        </Button>
      </VStack>
    </Box>
  )
}
