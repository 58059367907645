import { Box, useMediaQuery } from '@chakra-ui/react'
import { PageTitle, ResultCard } from 'component'
import { TestCategory } from 'data/testCategory'
import { TestType } from 'model'
import { useParams } from 'react-router-dom'

export default function TestResultScreen() {
  const param = useParams()
  const test: TestType = (param.name as unknown as TestType) ?? 'PHQ'
  const [isMobile] = useMediaQuery('(max-width: 480px)', {
    ssr: true,
    fallback: false,
  })

  return (
    <Box
      as="section"
      bg={isMobile ? 'gray.g4' : 'white'}
      minW={isMobile ? '' : '1280px'}
    >
      <PageTitle
        title={`${TestCategory[test].title} 결과`}
        isMobile={isMobile}
      />
      <Box as="section" px={isMobile ? '20px' : 44} pb={isMobile ? '60px' : 24}>
        <ResultCard name={test} disabled isMobile={isMobile} />
      </Box>
    </Box>
  )
}
