import { Flex, Text, IconButton } from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import { GoChevronLeft as LeftIcon } from 'react-icons/go'

type PathTitles = Record<string, string>

export function MobileHeader() {
  const navigate = useNavigate()
  const location = useLocation()
  const pathTitles: PathTitles = {
    '/login': '로그인',
    '/register': '회원가입',
    '/find/id': '아이디 찾기',
    '/find/password': '비밀번호 찾기',
    '/my/account': '내 정보',
    '/my/coupon': '내 정보',
    '/my/notice': '알림',
  }
  const title = pathTitles[location.pathname] || '페이지'

  function handleBack() {
    navigate(-1)
  }

  return (
    <>
      <Flex
        as="header"
        justifyContent="space-between"
        alignItems="center"
        h="60px"
        px="4px"
        borderBottom="1px"
        borderColor="#E3E3E3"
      >
        <IconButton
          icon={<LeftIcon />}
          fontSize="24px"
          aria-label="back button"
          bg="transparent"
          _active={{ transform: 'scale(0.98)' }}
          _hover={{ bg: 'transparent' }}
          onClick={handleBack}
        />
        <Text flex="1" textAlign="center" fontSize="17px" fontWeight="bold">
          {title}
        </Text>
        <div style={{ width: '40px' }} />
      </Flex>
    </>
  )
}
