import { Text, Box, Flex, Grid, useMediaQuery } from '@chakra-ui/react'
import React from 'react'

const info = [
  {
    step: 1,
    title: '브라이트는 서울대 출신 정신건강의학과 전문의가 만들었습니다.',
    desc: '서울대병원에서 기분장애에 대해 진료하고 연구한 전문의와 디지털 헬스케어 전문가들이 함께 개발하였습니다.<br/>정확하고 안전한 상담이 가능합니다.',
  },
  {
    step: 2,
    title:
      '브라이트는 익명화된 텍스트 상담으로 개인정보 이슈에 대한 부담이 적습니다.',
    desc: '회원 가입 시, 회원들의 정보를 의료 데이터에 준하여 보호합니다.<br/>상담 내용은 회사와 공유되지 않습니다. 개인을 특정할 수 없는, 비식별화 데이터로 나의 마음을 분석할 수 있습니다.',
  },
  {
    step: 3,
    title: '브라이트는 전문의와 심리상담사에게 원스톱으로 연결됩니다',
    desc: '텍스트 상담으로 해결하기 어려운 마음의 문제는 비대면 상담 / 대면 진료가 가능합니다. 원스톱 예약 시스템을 통해 정신건강의학과 전문의와 심리상담사에게 닿을 수 있습니다. 방문이 어려우신 분들은 비대면으로 심리상담사와 소통하실 수 있습니다.',
  },
]

const mobileInfo = [
  {
    step: 1,
    title: '브라이트는 서울대 출신 정신건강의학과 전문의가<br/>만들었습니다.',
    desc: '서울대병원에서 기분장애에 대해 진료하고 연구한<br/>전문의와 디지털 헬스케어 전문가들이 함께 개발하였습니<br/>다.정확하고 안전한 상담이 가능합니다.',
  },
  {
    step: 2,
    title:
      '브라이트는 익명화된 텍스트 상담으로 개인정보 이슈에<br/>대한 부담이 적습니다.',
    desc: '회원 가입 시, 회원들의 정보를 의료 데이터에 준하여<br/>보호합니다. 상담 내용은 회사와 공유되지 않습니다.<br/>개인을 특정할 수 없는, 비식별화 데이터로 나의 마음을<br/>분석할 수 있습니다.',
  },
  {
    step: 3,
    title: '브라이트는 전문의와 심리상담사에게 원스톱으로<br/>연결됩니다',
    desc: '텍스트 상담으로 해결하기 어려운 마음의 문제는 비대면<br/>상담 / 대면 진료가 가능합니다. 원스톱 예약 시스템을 통<br/>해 정신건강의학과 전문의와 심리상담사에게 닿을 수 있<br/>습니다. 방문이 어려우신 분들은 비대면으로 심리상담사<br/>와 소통하실 수 있습니다.',
  },
]

export default function ServiceScreen() {
  const [isMobile] = useMediaQuery('(max-width: 480px)', {
    ssr: true,
    fallback: false,
  })
  return (
    <>
      {!isMobile && (
        <>
          <Box as="section" px={44} py="4.5rem" minW="1280px">
            <Text as="h3" mb={5} color="primary.m2">
              내 손안의 마음건강 주치의, BRIGHT
            </Text>
            <Text as="b" mb={5}>
              브라이트는 기업을 가장 잘 아는 멘탈 테크 기업, 닥터프레소가 만든
              기업진단 마음 솔루션입니다. <br />
              조직 진단과 번아웃, 생산성 등 기업의 건강한 성장을 위한
              마음솔루션을 제공합니다.
              <br />
            </Text>
            <Text>
              마음건강의 문제는 모두가 한 번쯤은 경험합니다. <br />
              번아웃, 가정, 육아, 연애 등 삶을 영위하면서 우리는 업무와 관계
              속에서 때론 지치기도 합니다. <br />
              이러한 마음건강의 문제는 우리 삶을 괴롭힙니다. 브라이트를 통해
              여러분의 마음건강을 관리하세요.
              <br />
              <br />
              지속 가능한 건강한 성장을 위한 조직의 시작은, 모두의 안정적인
              마음부터입니다.
              <br />
              안정적인 우리 팀을 위한 가장 알맞은 솔루션, 브라이트.
              <br />
              <br />
              프로젝트의 성장을 위한 조직의 유지는, 꺾이지 않는 마음부터입니다.
              <br />
              목표를 향해 달려나갈 수 있는 솔루션, 브라이트.
              <br />
              <br />
              브라이트는 기업의 건강한 성장을 응원합니다.
            </Text>
          </Box>
          <Grid
            templateColumns="50px auto"
            as="section"
            bg="primary.m4"
            px={44}
            py="6.25rem"
            gap="3.375rem"
          >
            {info.map((item) => {
              return (
                <React.Fragment key={item.step}>
                  <Flex
                    key={`step ${item.step}`}
                    align="center"
                    justify="center"
                    color="white"
                    bg="primary.m2"
                    w="50px"
                    h="50px"
                    borderRadius="full"
                    textAlign="center"
                  >
                    <Text as="h3">{item.step}</Text>
                  </Flex>
                  <Box>
                    <Text as="h4" color="primary.m2">
                      {item.title}
                    </Text>
                    <Text
                      dangerouslySetInnerHTML={{ __html: item.desc }}
                    ></Text>
                  </Box>
                </React.Fragment>
              )
            })}
          </Grid>
        </>
      )}
      {isMobile && (
        <>
          <Flex
            fontSize="15px"
            py="40px"
            px="20px"
            gap="20px"
            flexDirection="column"
          >
            <Text as="b" color="primary.m2">
              브라이트는 기업을 가장 잘 아는 멘탈 테크 기업,
              <br />
              닥터프레소가 만든 기업진단 마음 솔루션입니다.
              <br />
              조직 진단과 번아웃, 생산성 등 기업의 건강한 성장을 위한
              <br />
              마음솔루션을 제공합니다.
            </Text>
            <Text color="#111111">
              마음건강의 문제는 모두가 한 번쯤은 경험합니다.
              <br />
              번아웃, 가정, 육아, 연애 등 삶을 영위하면서 우리는
              <br />
              업무와 관계 속에서 때론 지치기도 합니다.
              <br />
              이러한 마음건강의 문제는 우리 삶을 괴롭힙니다.
              <br />
              브라이트를 통해 여러분의 마음건강을 관리하세요.
            </Text>
            <Text color="#111111">
              지속 가능한 건강한 성장을 위한 조직의 시작은,
              <br />
              모두의 안정적인 마음부터입니다.
              <br />
              안정적인 우리 팀을 위한 가장 알맞은 솔루션, 브라이트.
            </Text>
            <Text color="#111111">
              프로젝트의 성장을 위한 조직의 유지는,
              <br /> 꺾이지 않는 마음부터입니다.
              <br />
              목표를 향해 달려나갈 수 있는 솔루션, 브라이트.
            </Text>
            <Text color="#111111">
              브라이트는 기업의 건강한 성장을 응원합니다.
            </Text>
          </Flex>
          <Flex
            bg="primary.m4"
            flexDirection="column"
            py="40px"
            px="20px"
            gap="28px"
          >
            {mobileInfo.map((item) => {
              return (
                <Flex flexDirection="column" key={item.step}>
                  <Flex
                    key={`step ${item.step}`}
                    align="center"
                    justify="center"
                    color="white"
                    bg="primary.m2"
                    w="31px"
                    h="31px"
                    borderRadius="full"
                    textAlign="center"
                    mb="12px"
                  >
                    <Text as="b" fontSize="15px">
                      {item.step}
                    </Text>
                  </Flex>
                  <Box>
                    <Text
                      as="b"
                      color="primary.m2"
                      fontSize="15px"
                      mb="8px"
                      dangerouslySetInnerHTML={{ __html: item.title }}
                    />
                    <Text
                      fontSize="15px"
                      dangerouslySetInnerHTML={{ __html: item.desc }}
                    />
                  </Box>
                </Flex>
              )
            })}
          </Flex>
        </>
      )}
    </>
  )
}
