export const TestCategory = {
  PHQ: {
    id: 1,
    title: '우울 검사',
    name: '우울 (PHQ-9)',
    fullname: 'Patient Health Questionnaire - 9',
    maxScore: 27,
    standard: 5,
    desc: '다음 문항들을 자세히 읽어보시고 일상생활에서 느끼는 바를 가장 잘 나타내 주는 문항에 체크하세요.',
  },
  ISI: {
    id: 2,
    title: '불면 검사',
    name: '불면 (ISI)',
    fullname: 'Insomnia Severity Index',
    maxScore: 28,
    standard: 7,
    desc: '지난 한 달 동안 당신의 불면증이 얼마나 심했나요?',
  },
  SSI: {
    id: 3,
    title: '자살생각 검사',
    name: '자살생각 (SSI)',
    fullname: 'Scale for Sucide Ideation - Beck',
    maxScore: 38,
    standard: 7,
    desc: '다음 문항들을 자세히 읽어보시고 일상생활에서 느끼는 바를 가장 잘 나타내 주는 문항에 체크하세요.',
  },
}

const TestState = {
  mild: {
    text: 'Mild',
    color: 'green',
  },
  moderate: {
    text: 'Moderate',
    color: 'yellow',
  },
  severe: {
    text: 'Severe',
    color: 'red',
  },
}

export const TestResultStandard = {
  PHQ: [
    {
      desc: '우울감이 없거나, 아주 경미한 수준입니다. 우울감에 대해서는 걱정하지 않으셔도 괜찮겠습니다.',
      state: TestState.mild,
    },
    {
      desc: '경미한 우울감이 관찰됩니다. 한 달 뒤 다시 검사를 해보세요.',
      state: TestState.mild,
    },
    {
      desc: '중증도의 우울감이 관찰됩니다. 우울감에 대해서는 치료가 필요할 수 있겠습니다. 브라이트를 더욱 자주 들려주세요.',
      state: TestState.moderate,
    },
    {
      desc: '다소 심각한 우울감이 관찰됩니다. 어쩌면 전문적인 치료가 필요할 수 있겠습니다. 병원을 방문해보시는 것은 어떨까요?',
      state: TestState.severe,
    },
    {
      desc: '아주 심각한 우울감이 관찰됩니다. 전문적인 치료가 더욱 도움이 될 것 같네요.',
      state: TestState.severe,
    }
  ],
  ISI: [
    {
      desc: '유의한 불면증상은 없거나, 아주 경미한 수준입니다. 수면에 대해서는 걱정하지 않으셔도 될 것 같습니다.',
      state: TestState.mild,
    },
    {
      desc: '약간의 불면증이 있으시네요. 좋은 수면을 위해 가벼운 운동을 해보시는 것은 어떨까요?',
      state: TestState.mild,
    },
    {
      desc: '중증도의 불면증이 관찰됩니다. 일상에 영향을 받을 정도로 불편하시다면 전문적인 치료가 필요할 수 있겠습니다.',
      state: TestState.moderate,
    },
    {
      desc: '심각한 불면증이 관찰됩니다. 전문적인 치료가 더욱 도움을 줄 수 있습니다.',
      state: TestState.severe,
    },
  ],
  SSI: [
    {
      desc: '위험한 수준으 자살사고는 아닙니다. 하지만 자살에 대한 생각이 늘어난다면 브라이트를 더 자주 찾아주세요.',
      state: TestState.mild,
    },
    {
      desc: '자살 생각에 대해서 전문적인 도움이 필요할 것으로 보입니다. 병원을 방문해보시는 것은 어떨까요?',
      state: TestState.severe
    }
  ]
}
