/* eslint-disable multiline-ternary */
import {
  Box,
  Input,
  Select,
  Text,
  VStack,
  HStack,
  Spacer,
  useToast,
  useMediaQuery,
  Flex,
  Divider,
} from '@chakra-ui/react'
import { Button, Card, ToastTheme } from 'component'

import dayjs from 'dayjs'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { UserService } from 'service'
import { validPassword } from 'helpers'
import { MobileHeader, MobileMyNav } from 'component/mobile'

const year = Array.from({ length: 94 }, (_, i) => i + 1930)
const month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
const day = Array.from({ length: 31 }, (_, i) => i + 1)

export default function AccountScreen() {
  const token = localStorage.getItem('access') ?? ''
  const navigate = useNavigate()
  const toast = useToast()
  const [isMobile] = useMediaQuery('(max-width: 480px)', {
    ssr: false,
    fallback: false,
  })

  useQuery(['user'], async () => await UserService.getUser(token), {
    onSuccess: (data) => {
      setNick(data?.nick)
      setGender(data?.gender ?? '')
      setBirthY(data.birth ? dayjs(data.birth).get('y') : undefined)
      setBirthM(data.birth ? dayjs(data.birth).get('m') + 1 : undefined)
      setBirthD(data.birth ? dayjs(data.birth).get('d') + 1 : undefined)
      setEmail(data?.email)
    },
  })
  const [nick, setNick] = useState('')
  const [gender, setGender] = useState('')
  const [birthY, setBirthY] = useState<number>()
  const [birthM, setBirthM] = useState<number>()
  const [birthD, setBirthD] = useState<number>()
  const [email, setEmail] = useState('')

  const [currentPass, setCurrentPass] = useState('')
  const [newPass, setNewPass] = useState('')
  const [confirmPass, setConfirmPass] = useState('')

  const changePassword = useMutation(
    ['changePassword'],
    async () => {
      try {
        await UserService.changePassword(
          { password: currentPass, newPassword: newPass },
          token
        )
      } catch (err: any) {
        const error: Error = err.response.data as Error
        toast({
          description: error.message,
          ...ToastTheme.error,
        })
        throw new Error('error')
      }
    },
    {
      onSuccess: () => {
        toast({
          description: '비밀번호가 변경되었습니다. 다시 로그인 해주세요.',
          ...ToastTheme.success,
        })
        localStorage.setItem('access', '')
        navigate('/login')
      },
    }
  )

  const changeUserInfo = useMutation(
    ['changeUserInfo'],
    async () => {
      await UserService.changeUserInfo(
        {
          nick,
          gender,
          birth:
            birthY === undefined || birthM === undefined || birthD === undefined
              ? undefined
              : `${birthY}-${birthM}-${birthD}`,
        },
        token
      )
    },
    {
      onSuccess: () => {
        toast({
          description: '정보가 변경되었습니다.',
          ...ToastTheme.success,
        })
      },
    }
  )

  return (
    <>
      {isMobile ? (
        <>
          <MobileHeader />
          <MobileMyNav />
          <Flex flexDir="column" w="100%" px="20px" pt="27px" pb="60px">
            <Text as="h1" fontSize="20px" mb="24px">
              기본 정보
            </Text>
            <VStack gap="20px">
              <Box w="100%">
                <Text as="b" fontSize="15px" mb={2} display="block">
                  닉네임
                </Text>
                <Input
                  placeholder={'닉네임'}
                  value={nick}
                  h="50px"
                  bg="#F7F7F7"
                  fontSize="15px"
                  variant="filled"
                  onChange={(e) => {
                    e.preventDefault()
                    setNick(e.target.value)
                  }}
                />
              </Box>
              <Box w="100%">
                <Text as="b" fontSize="15px" mb={2} display="block">
                  이메일
                </Text>
                <Input
                  value={email}
                  h="50px"
                  placeholder={'이메일'}
                  bg="#F7F7F7"
                  fontSize="15px"
                  variant="filled"
                  color="#8D8D8D"
                  isDisabled={true}
                />
              </Box>
              <Box w="350px">
                <Text as="b" mb={2} display="block">
                  성별{' '}
                  <Text as="span" color="gray.g2">
                    (선택)
                  </Text>
                </Text>
                <Select
                  placeholder="성별"
                  m={0}
                  p={0}
                  value={gender}
                  h="50px"
                  bg="#F7F7F7"
                  fontSize="15px"
                  variant="filled"
                  onChange={(e) => {
                    setGender(e.target.value)
                  }}
                >
                  <option value="Male">남</option>
                  <option value="Female">여</option>
                </Select>
              </Box>
              <Box w="350px">
                <Text as="b" mb={2} display="block">
                  생년월일{' '}
                  <Text as="span" color="gray.g2">
                    (선택)
                  </Text>
                </Text>
                <HStack gap={3}>
                  <Select
                    placeholder="년"
                    m={0}
                    p={0}
                    h="50px"
                    bg="#F7F7F7"
                    fontSize="15px"
                    variant="filled"
                    value={birthY}
                    onChange={(e) => {
                      setBirthY(Number(e.target.value))
                    }}
                  >
                    {year.map((y) => {
                      return (
                        <option key={y} value={y}>
                          {y}
                        </option>
                      )
                    })}
                  </Select>
                  <Select
                    placeholder="월"
                    m={0}
                    p={0}
                    h="50px"
                    bg="#F7F7F7"
                    fontSize="15px"
                    variant="filled"
                    value={birthM}
                    onChange={(e) => {
                      setBirthM(Number(e.target.value))
                    }}
                  >
                    {month.map((m) => {
                      return (
                        <option key={m} value={m}>
                          {m}
                        </option>
                      )
                    })}
                  </Select>
                  <Select
                    placeholder="일"
                    m={0}
                    p={0}
                    h="50px"
                    bg="#F7F7F7"
                    fontSize="15px"
                    variant="filled"
                    value={birthD}
                    onChange={(e) => {
                      setBirthD(Number(e.target.value))
                    }}
                  >
                    {day.map((d) => {
                      return (
                        <option key={d} value={d}>
                          {d}
                        </option>
                      )
                    })}
                  </Select>
                </HStack>
              </Box>
              <Button
                style={{
                  borderRadius: 'xl',
                  width: '100%',
                  fontWeight: 'bold',
                  fontSize: '15px',
                }}
                onClick={() => {
                  changeUserInfo.mutate()
                }}
              >
                변경
              </Button>
            </VStack>
            <Divider borderColor="gray.g3" my="32px" />
            <Text as="h1" fontSize="20px" mb="24px">
              비밀번호
            </Text>
            <VStack gap={5}>
              <Box w="350px">
                <Text as="b" fontSize="15px" mb={2} display="block">
                  현재 비밀번호
                </Text>
                <Input
                  placeholder={'현재 비밀번호'}
                  value={currentPass}
                  onChange={(e) => {
                    e.preventDefault()
                    setCurrentPass(e.target.value)
                  }}
                  bg="#F7F7F7"
                  fontSize="15px"
                  variant="filled"
                  h="50px"
                  type="password"
                />
              </Box>
              <Box w="350px">
                <Text as="b" fontSize="15px" mb={2} display="block">
                  새 비밀번호
                </Text>
                <Input
                  placeholder={'새 비밀번호'}
                  value={newPass}
                  onChange={(e) => {
                    e.preventDefault()
                    setNewPass(e.target.value)
                  }}
                  bg="#F7F7F7"
                  fontSize="15px"
                  variant="filled"
                  h="50px"
                  type="password"
                />
                <Text color="red" display="block" fontSize="sm">
                  {newPass.length === 0 || validPassword(newPass)
                    ? ''
                    : '8자 이상, 숫자, 문자를 포함해주세요'}
                </Text>
              </Box>
              <Box w="350px">
                <Text as="b" fontSize="15px" mb={2} display="block">
                  새 비밀번호 확인
                </Text>
                <Input
                  placeholder={'새 비밀번호 확인'}
                  value={confirmPass}
                  onChange={(e) => {
                    e.preventDefault()
                    setConfirmPass(e.target.value)
                  }}
                  bg="#F7F7F7"
                  fontSize="15px"
                  variant="filled"
                  h="50px"
                  type="password"
                />
                <Text color="red" display="block" fontSize="sm">
                  {confirmPass.length === 0 || newPass === confirmPass
                    ? ''
                    : '입력한 비밀번호와 동일하게 입력해주세요'}
                </Text>
              </Box>
              <Button
                style={{
                  borderRadius: 'xl',
                  width: '100%',
                  fontWeight: 'bold',
                  fontSize: '15px',
                }}
                onClick={() => {
                  changePassword.mutate()
                }}
              >
                변경
              </Button>
            </VStack>
          </Flex>
        </>
      ) : (
        <Box minW={isMobile ? '' : '1280px'}>
          <Text as="h1">개인정보 관리</Text>
          <Spacer h={10} />
          <Card title="기본 정보">
            <VStack gap={5}>
              <Box w="350px">
                <Text as="b" mb={2} display="block">
                  닉네임
                </Text>
                <Input
                  placeholder={'닉네임'}
                  value={nick}
                  onChange={(e) => {
                    e.preventDefault()
                    setNick(e.target.value)
                  }}
                  bg="white"
                />
              </Box>
              <Box w="350px">
                <Text as="b" mb={2} display="block">
                  이메일
                </Text>
                <Input
                  value={email}
                  placeholder={'이메일'}
                  bg="white"
                  isDisabled={true}
                />
              </Box>
              <Box w="350px">
                <Text as="b" mb={2} display="block">
                  성별{' '}
                  <Text as="span" color="gray.g2">
                    (선택)
                  </Text>
                </Text>
                <Select
                  placeholder="성별"
                  bg="white"
                  m={0}
                  p={0}
                  value={gender}
                  onChange={(e) => {
                    setGender(e.target.value)
                  }}
                >
                  <option value="Male">남</option>
                  <option value="Female">여</option>
                </Select>
              </Box>
              <Box w="350px">
                <Text as="b" mb={2} display="block">
                  생년월일{' '}
                  <Text as="span" color="gray.g2">
                    (선택)
                  </Text>
                </Text>
                <HStack gap={3}>
                  <Select
                    placeholder="년"
                    bg="white"
                    m={0}
                    p={0}
                    value={birthY}
                    onChange={(e) => {
                      setBirthY(Number(e.target.value))
                    }}
                  >
                    {year.map((y) => {
                      return (
                        <option key={y} value={y}>
                          {y}
                        </option>
                      )
                    })}
                  </Select>
                  <Select
                    placeholder="월"
                    bg="white"
                    m={0}
                    p={0}
                    value={birthM}
                    onChange={(e) => {
                      setBirthM(Number(e.target.value))
                    }}
                  >
                    {month.map((m) => {
                      return (
                        <option key={m} value={m}>
                          {m}
                        </option>
                      )
                    })}
                  </Select>
                  <Select
                    placeholder="일"
                    bg="white"
                    m={0}
                    p={0}
                    value={birthD}
                    onChange={(e) => {
                      setBirthD(Number(e.target.value))
                    }}
                  >
                    {day.map((d) => {
                      return (
                        <option key={d} value={d}>
                          {d}
                        </option>
                      )
                    })}
                  </Select>
                </HStack>
              </Box>
              <Button
                style={{ borderRadius: 'xl', width: '350px' }}
                onClick={() => {
                  changeUserInfo.mutate()
                }}
              >
                변경
              </Button>
            </VStack>
          </Card>
          <Spacer h={10} />
          <Card title="비밀번호">
            <VStack gap={5}>
              <Box w="350px">
                <Text as="b" mb={2} display="block">
                  현재 비밀번호
                </Text>
                <Input
                  placeholder={'현재 비밀번호'}
                  value={currentPass}
                  onChange={(e) => {
                    e.preventDefault()
                    setCurrentPass(e.target.value)
                  }}
                  bg="white"
                  type="password"
                />
              </Box>
              <Box w="350px">
                <Text as="b" mb={2} display="block">
                  새 비밀번호
                </Text>
                <Input
                  placeholder={'새 비밀번호'}
                  value={newPass}
                  onChange={(e) => {
                    e.preventDefault()
                    setNewPass(e.target.value)
                  }}
                  bg="white"
                  type="password"
                />
                <Text color="red" display="block" fontSize="sm">
                  {newPass.length === 0 || validPassword(newPass)
                    ? ''
                    : '8자 이상, 숫자, 문자를 포함해주세요'}
                </Text>
              </Box>
              <Box w="350px">
                <Text as="b" mb={2} display="block">
                  새 비밀번호 확인
                </Text>
                <Input
                  placeholder={'새 비밀번호 확인'}
                  value={confirmPass}
                  onChange={(e) => {
                    e.preventDefault()
                    setConfirmPass(e.target.value)
                  }}
                  bg="white"
                  type="password"
                />
                <Text color="red" display="block" fontSize="sm">
                  {confirmPass.length === 0 || newPass === confirmPass
                    ? ''
                    : '입력한 비밀번호와 동일하게 입력해주세요'}
                </Text>
              </Box>
              <Button
                style={{ borderRadius: 'xl', width: '350px' }}
                onClick={() => {
                  changePassword.mutate()
                }}
              >
                변경
              </Button>
            </VStack>
          </Card>
        </Box>
      )}
    </>
  )
}
