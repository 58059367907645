import {
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Spacer,
  Text,
  useMediaQuery,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { Button, DisclosureObject, Modal, ToastTheme } from 'component'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { AuthService } from 'service'

export function VerifyEmailModal({
  state,
  email,
  resend,
  verify,
}: {
  state: DisclosureObject
  email: string
  resend: () => void
  verify: () => void
}) {
  const toast = useToast()
  const [code, setCode] = useState<string>('')
  const [timer, setTimer] = useState<number>(600)
  const [isMobile] = useMediaQuery('(max-width: 480px)', {
    ssr: true,
    fallback: false,
  })

  useEffect(() => {
    if (state.isOpen) {
      const count = setInterval(() => {
        if (timer === 0) {
          clearInterval(count)
        } else {
          setTimer(timer - 1)
        }
      }, 1000)
      return () => {
        clearInterval(count)
      }
    }
  }, [timer, state])

  const checkAuthCode = useMutation(['checkAuthCode'], async () => {
    try {
      await AuthService.checkAuthCode({ email, token: code })
      toast({
        description: '인증되었습니다.',
        ...ToastTheme.success,
      })
      verify()
      state.onClose()
    } catch (err) {
      toast({
        description: '이메일을 확인하거나 재시도해주세요.',
        ...ToastTheme.error,
      })
      throw new Error('invalid token')
    }
  })

  return (
    <Modal title="이메일 인증하기" state={state} isMobile={isMobile}>
      <VStack gap={isMobile ? 3 : 3} align="stretch">
        <Text fontSize="15px">
          이메일로 전송된 6자리 인증 코드를 입력해주세요.
        </Text>
        <InputGroup>
          <Input
            value={code}
            fontSize={isMobile ? '15px' : '16px'}
            h={isMobile ? '50px' : '40px'}
            variant={isMobile ? 'filled' : 'outline'}
            bgColor={isMobile ? '#F7F7F7' : 'white'}
            onChange={(e) => {
              setCode(e.target.value)
            }}
            placeholder="인증 코드를 입력해주세요."
          />
          <InputRightElement>
            <Text color="red" fontSize={isMobile ? '15px' : '16px'}>
              {parseInt((timer / 60).toString())}:
              {(timer % 60).toString().padStart(2, '0')}
            </Text>
          </InputRightElement>
        </InputGroup>
        <Text
          as="a"
          color="primary.m2"
          cursor="pointer"
          fontSize={isMobile ? '13px' : '16px'}
          _focus={{ textDecoration: 'underline' }}
          onClick={() => {
            resend()
            setTimer(600)
          }}
        >
          인증 코드 재전송
        </Text>
      </VStack>
      <Spacer h={6} />
      <SimpleGrid columns={2} gap={2.5}>
        <Button
          style={{ borderRadius: 'xl' }}
          theme="gray"
          onClick={state.onClose}
        >
          취소
        </Button>
        <Button
          style={{ borderRadius: 'xl' }}
          onClick={() => {
            checkAuthCode.mutate()
          }}
        >
          확인
        </Button>
      </SimpleGrid>
    </Modal>
  )
}
