import { AccessToken, User } from 'model'
import { api } from '.'

class AuthService {
  async signup(
    data: Pick<User, 'nick' | 'email' | 'password' | 'gender' | 'birth'>
  ) {
    return await api.post<AccessToken>('auth/signup', data)
  }

  async checkId(data: Pick<User, 'nick'>) {
    return await api.post('auth/verify/nick', data)
  }

  async login(data: Pick<User, 'nick' | 'password'>) {
    return await api.post<AccessToken>('auth/login', data)
  }

  async logout() {
    localStorage.removeItem('access')
  }

  async refresh() {
    return await api.post<AccessToken>('auth/refresh', {})
  }

  async verify(token: string) {
    return await api.post('auth/verify', { token }, token)
  }

  async checkEmailValid(data: Pick<User, 'email'>) {
    return await api.post('auth/verify/email', data)
  }

  async sendAuthEmail(data: Pick<User, 'email'>) {
    return await api.post('auth/code', data)
  }

  async checkAuthCode(data: Pick<User, 'email'> & { token: string }) {
    return await api.post('auth/verify/code', data)
  }

  async findIdByEmail(data: Pick<User, 'email'>) {
    return await api.post('auth/find/id', data)
  }

  async resetPassword(data: { email: string; password: string }) {
    return await api.post('auth/reset/password', data)
  }
}

export default new AuthService()
