import { Box, Flex, Input, Text, useMediaQuery } from '@chakra-ui/react'
import { Button } from 'component'

export type RegisterInputProp = {
  id: number
  label: string
  value: string
  setValue: (arg: any) => void
  type?: string

  isValid?: boolean
  error?: string

  Btn?: {
    label: string
    onClick: () => void
    disabled?: boolean
  }
}

export function InputForm({ item }: { item: RegisterInputProp }) {
  const [isMobile] = useMediaQuery('(max-width: 480px)', {
    ssr: true,
    fallback: false,
  })

  return (
    <Box w={isMobile ? '100%' : '540px'}>
      <Text as="b" mb={isMobile ? '12px' : 2} display="block">
        {item.label}
      </Text>
      <Flex gap={2}>
        <Input
          placeholder={item.label}
          value={item.value}
          onChange={(e) => {
            e.preventDefault()
            item.setValue(e.target.value)
          }}
          bg={isMobile ? '#F7F7F7' : 'white'}
          variant={isMobile ? 'filled' : 'outline'}
          h={isMobile ? '50px' : '40px'}
          type={item.type}
        />
        {item.Btn && (
          <Button
            style={{
              borderRadius: 'xl',
              px: '4',
              w: '120px',
              fontWeight: 'medium',
              whiteSpace: 'nowrap',
            }}
            onClick={item.Btn.onClick}
            disabled={item.Btn.disabled}
          >
            {item.Btn.label}
          </Button>
        )}
      </Flex>
      <Text color="red" display="block" fontSize="sm">
        {item.isValid ? '' : item.error}
      </Text>
    </Box>
  )
}
