import { Box, Flex, Icon, Spacer, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { elapsedTime } from 'helpers'
import { Diary } from 'model'
import { FaCircle } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

export function DiaryCard({
  diary,
  isMobile,
}: {
  diary: Diary
  isMobile: boolean
}) {
  const navigate = useNavigate()

  return (
    <Box
      boxShadow="sm"
      pt={6}
      minW={isMobile ? '220px' : 28}
      w={isMobile ? '220px' : 96}
      bg="white"
      borderRadius="2xl"
    >
      <Flex px={6} justifyContent="space-between">
        <Flex direction="column">
          <Text as="b">{dayjs(diary?.date).format('MM월 DD일')}</Text>
          <Text textStyle="smaller">{elapsedTime(diary.createdAt)}</Text>
        </Flex>
        <Flex direction="column" alignItems="center">
          <Icon
            as={FaCircle}
            w={5}
            h={5}
            color={!diary.answer ? 'gray.g2' : 'yellow'}
            mb="5px"
          />
          {diary.answer && !diary.read && (
            <Icon as={FaCircle} w={1.5} h={1.5} color="red" />
          )}
        </Flex>
      </Flex>
      <Spacer h={6} />
      <Text
        px={6}
        pb={6}
        textStyle="small"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {diary.emotion}
      </Text>
      <Text
        p={3}
        as="b"
        display="block"
        borderBottomLeftRadius="2xl"
        borderBottomRightRadius="2xl"
        textAlign="center"
        w="100%"
        bg={!diary.answer ? 'gray.g3' : diary.read ? 'gray.g2' : 'primary.m2'}
        color={!diary.answer ? '' : 'white'}
        onClick={() => {
          navigate(`/diary/${diary.id}`)
        }}
        cursor="pointer"
      >
        {!diary.answer ? '답변대기중' : '답변보기'}
      </Text>
    </Box>
  )
}
