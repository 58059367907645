import { Question } from 'model'

export const SSI: Question[] = [
  {
    id: 1,
    question: '살고 싶은 생각은?',
    answer: [
      { score: 0, desc: '보통 혹은 많이 있다.' },
      { score: 1, desc: '약간 있다.' },
      { score: 2, desc: '전혀 없다.' },
    ],
  },
  {
    id: 2,
    question: '죽고 싶은 생각은?',
    answer: [
      { score: 0, desc: '전혀 없다.' },
      { score: 1, desc: '약간 있다.' },
      { score: 2, desc: '보통 혹은 많이 있다.' },
    ],
  },
  {
    id: 3,
    question: '삶과 죽음에 대해 어떻게 생각하십니까?',
    answer: [
      { score: 0, desc: '사는 것이 죽는 것보다 낫다.' },
      { score: 1, desc: '사는 것이나 죽는 것이나 마찬가지다.' },
      { score: 2, desc: '죽는 것이 사는 것 보다 낫다.' },
    ],
  },
  {
    id: 4,
    question: '실제로 자살을 시도하려는 욕구가 있습니까?',
    answer: [
      { score: 0, desc: '전혀 없다.' },
      { score: 1, desc: '약간 있다.' },
      { score: 2, desc: '보통 혹은 많이 있다.' },
    ],
  },
  {
    id: 5,
    question: '삶에 대한 당신의 태도는?',
    answer: [
      { score: 0, desc: '좀 더 나은 삶을 위해 노력한다.' },
      { score: 1, desc: '사는 것, 죽는 것 모두 운명이라고 생각한다.' },
      { score: 2, desc: '특별히 나는 더 살고자 하는 마음이 없다.' },
    ],
  },
  {
    id: 6,
    question: '자살하고 싶은 욕구나 생각이 얼마나 오랫동안 지속됩니까?',
    answer: [
      {
        score: 0,
        desc: '잠깐 그런 생각이 들다가 곧 사라진다 (전혀 생각해보지 않았다).',
      },
      { score: 1, desc: '한동안 그런 생각이 지속된다.' },
      { score: 2, desc: '거의 항상 그런 생각이 지속된다.' },
    ],
  },
  {
    id: 7,
    question: '자살에 대한 생각을 얼마나 자주 합니까?',
    answer: [
      { score: 0, desc: '거의 하지 않는다.' },
      { score: 1, desc: '가끔 한다.' },
      { score: 2, desc: '거의 항상 한다.' },
    ],
  },
  {
    id: 8,
    question: '자살에 대한 욕구나 생각이 들 때 당신은 어떻게 하십니까?',
    answer: [
      { score: 0, desc: '그런 생각을 없애기 위해 다른 생각에 몰두한다.' },
      { score: 1, desc: '그런 생각에 별로 개의치 않는다.' },
      { score: 2, desc: '그런 생각을 받아들인다.' },
    ],
  },
  {
    id: 9,
    question: '스스로 자살하고 싶은 생각을 억제할 수 있습니까?',
    answer: [
      { score: 0, desc: '충분히 억제할 수 있다.' },
      { score: 1, desc: '억제할 수 있는지 확신할 수 없다.' },
      { score: 2, desc: '전혀 억제할 수 없을 것 같다.' },
    ],
  },
  {
    id: 10,
    question:
      '실제로 자살을 시도하려고 할 때 주변 환경(가족/친구/종교/다시 살 수 없다는 생각 등)이 어떠한 영향을 주리라 생각하십니까?',
    answer: [
      { score: 0, desc: '결코 자살을 시도하지 못할 것이다.' },
      { score: 1, desc: '자살을 망설일 것이다.' },
      { score: 2, desc: '전혀 영향을 받지 않을 것이다.' },
    ],
  },
  {
    id: 11,
    question: '자살에 대해 깊이 생각해 본 이유는 무엇입니까?',
    answer: [
      { score: 0, desc: '자살에 대해 생각해 본적이 없다.' },
      {
        score: 1,
        desc: '주변들의 관심을 끌고 보복하거나, 현실 도피의 방법으로',
      },
      { score: 2, desc: '현실에서 벗어나기 위한 방법으로' },
    ],
  },
  {
    id: 12,
    question:
      '자살에 대해 생각했을 때 그 방법까지 구체적으로 계획해 보았습니까?',
    answer: [
      { score: 0, desc: '자살에 대해 생각해 본적이 없다.' },
      { score: 1, desc: '별로 현실적이지 못하고 사용할 기회도 없을 것이다.' },
      {
        score: 2,
        desc: '생각한 방법이 현실적으로 실현가능하며 기회나 방법이 생길 것이다.',
      },
    ],
  },
  {
    id: 13,
    question: '당신은 실제로 자살을 할 수 있다고 생각합니까?',
    answer: [
      {
        score: 0,
        desc: '용기가 없고 겁이 나서 실제로는 자살을 하지 못할 것이다.',
      },
      { score: 1, desc: '자살할 용기와 능력이 있는지 확신 할 수 없다.' },
      { score: 2, desc: '자살할 용기와 자신이 있다.' },
    ],
  },
  {
    id: 14,
    question: '정말 자살을 시도할 것이라고 확신합니까?',
    answer: [
      { score: 0, desc: '전혀 그렇지 않다.' },
      { score: 1, desc: '잘 모르겠다.' },
      { score: 2, desc: '그렇다.' },
    ],
  },
  {
    id: 15,
    question: '자살을 시도하기 위해 실제로 준비한 적이 있습니까?',
    answer: [
      { score: 0, desc: '없다.' },
      { score: 1, desc: '부분적으로 했다(예: 약을 사 모으기 시작함).' },
      {
        score: 2,
        desc: '완전하게 준비했다(예: 죽기에 충분한 다량의 약을 사 모았다).',
      },
    ],
  },
  {
    id: 16,
    question: '자살하려는 글(유서)를 써 본적이 있습니까?',
    answer: [
      { score: 0, desc: '없다.' },
      { score: 1, desc: '쓰려고 생각만 해 보았다.' },
      { score: 2, desc: '시작했다가 그만 두었다.' },
      { score: 3, desc: '다 써놓았다.' },
    ],
  },
  {
    id: 17,
    question:
      '죽음을 예상하고 마지막으로 한 일은? (예: 나에게 소중한 물건을 남에게 주었다. 유언 등)',
    answer: [
      { score: 0, desc: '없다.' },
      { score: 1, desc: '생각만 해 보았거나 약간의 정리를 했다.' },
      { score: 2, desc: '확실한 계획을 세웠거나 다 정리를 해 놓았다.' },
    ],
  },
  {
    id: 18,
    question: '자살에 관한 생각을 다른사람에게 이야기 한 적이 있습니까?',
    answer: [
      { score: 0, desc: '자살에 대해 생각해 본적이 없다.' },
      { score: 1, desc: '다른사람에게 이야기했다.' },
      { score: 2, desc: '그런 생각을 속이고 숨겼다.' },
    ],
  },
]
