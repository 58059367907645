/* eslint-disable multiline-ternary */
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Text,
  IconButton,
  Flex,
  Divider,
  VStack,
  Button,
  useToast,
} from '@chakra-ui/react'
import { GoChevronRight as RightIcon } from 'react-icons/go'
import { User } from 'model'
import { AuthService } from 'service'
import { useNavigate } from 'react-router-dom'
import { ToastTheme } from '../toast'
import { Dispatch, SetStateAction } from 'react'

function MenuModal({
  isOpen,
  onClose,
  user,
  setUser,
  token,
  validate,
}: {
  isOpen: boolean
  onClose: () => void
  user?: User
  setUser: Dispatch<SetStateAction<User | undefined>>
  token: string
  validate: boolean
}) {
  const navigate = useNavigate()
  const toast = useToast()

  return (
    <>
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader mt="68px">
            {user ? (
              <>
                <Text as="h2">{user.nick}</Text>
                <Text fontSize="small">{user.email}</Text>
              </>
            ) : (
              <Flex justify="space-between" align="center">
                <Text>로그인을 해주세요.</Text>
                <IconButton
                  icon={<RightIcon />}
                  fontSize="24px"
                  aria-label="right button"
                  bg="transparent"
                  _hover={{ bg: 'transparent' }}
                  _active={{ transform: 'scale(0.98)' }}
                  onClick={() => {
                    navigate('/login')
                  }}
                />
              </Flex>
            )}
          </DrawerHeader>
          <DrawerBody>
            <VStack textAlign="left" w="100%">
              <Divider borderColor="gray.g3" borderWidth="1px" />
              <Button
                bg="transparent"
                w="100%"
                justifyContent="left"
                _hover={{
                  bgColor: '#E4EEF2',
                  color: 'primary.m2',
                  borderRadius: '8px',
                }}
                onClick={() => {
                  navigate('/')
                  onClose()
                }}
              >
                홈
              </Button>
              <Button
                bg="transparent"
                w="100%"
                justifyContent="left"
                _hover={{
                  bgColor: '#E4EEF2',
                  color: 'primary.m2',
                  borderRadius: '8px',
                }}
                onClick={() => {
                  navigate('/service')
                  onClose()
                }}
              >
                서비스 소개
              </Button>
              <Button
                bg="transparent"
                w="100%"
                justifyContent="left"
                _hover={{
                  bgColor: '#E4EEF2',
                  color: 'primary.m2',
                  borderRadius: '8px',
                }}
                onClick={() => {
                  if (!token) {
                    toast({
                      description: '로그인 후 사용해주세요.',
                      ...ToastTheme.error,
                    })
                    return
                  }
                  // if (!validate) {
                  //   toast({
                  //     description: '이용권 등록 후 사용해주세요.',
                  //     ...ToastTheme.error,
                  //   })
                  //   return
                  // }
                  navigate('/diary')
                  onClose()
                }}
              >
                마음일기
              </Button>
              <Button
                bg="transparent"
                w="100%"
                justifyContent="left"
                _hover={{
                  bgColor: '#E4EEF2',
                  color: 'primary.m2',
                  borderRadius: '8px',
                }}
                onClick={() => {
                  if (!token) {
                    toast({
                      description: '로그인 후 사용해주세요.',
                      ...ToastTheme.error,
                    })
                    return
                  }
                  // if (!validate) {
                  //   toast({
                  //     description: '이용권 등록 후 사용해주세요.',
                  //     ...ToastTheme.error,
                  //   })
                  //   return
                  // }
                  navigate('/test')
                  onClose()
                }}
              >
                마음검사
              </Button>
              <Divider borderColor="gray.g3" borderWidth="1px" />
              <Button
                bg="transparent"
                w="100%"
                justifyContent="left"
                _hover={{
                  bgColor: '#E4EEF2',
                  color: 'primary.m2',
                  borderRadius: '8px',
                }}
                onClick={() => {
                  if (!token) {
                    navigate('/login')
                  } else {
                    navigate('/my/account')
                  }
                }}
              >
                내 정보
              </Button>
              {user && (
                <Button
                  bg="transparent"
                  w="100%"
                  justifyContent="left"
                  _hover={{
                    bgColor: '#E4EEF2',
                    color: 'primary.m2',
                    borderRadius: '8px',
                  }}
                  onClick={async () => {
                    await AuthService.logout()
                    toast({
                      description: '로그아웃 되었습니다',
                      ...ToastTheme.success,
                    })
                    setUser(undefined)
                    navigate('/')
                    onClose()
                  }}
                >
                  로그아웃
                </Button>
              )}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default MenuModal
