import { SimpleGrid, Spacer, Text } from '@chakra-ui/react'
import { Button, DisclosureObject, Modal } from 'component'

export function ConfirmModal({
  state,
  onYes,
  isMobile,
}: {
  state: DisclosureObject
  onYes: () => void
  isMobile: boolean
}) {
  return (
    <Modal title="마음일기 작성 완료" state={state} isMobile={isMobile}>
      <Text fontSize={isMobile ? '15px' : ''}>
        한 번 작성한 마음일기는 수정이 불가능해요.
        <br />
        작성하신 마음일기를 등록하시겠어요?
      </Text>
      <Spacer h={6} />
      <SimpleGrid columns={2} gap={2.5}>
        <Button
          style={{ borderRadius: 'xl' }}
          theme="gray"
          onClick={state.onClose}
        >
          아니오
        </Button>
        <Button style={{ borderRadius: 'xl' }} onClick={onYes}>
          네
        </Button>
      </SimpleGrid>
    </Modal>
  )
}
