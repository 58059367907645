import {
  Flex,
  HStack,
  Text,
  IconButton,
  Popover,
  PopoverTrigger,
  Box,
  Image,
  useToast,
  useMediaQuery,
} from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  FaRegBell as BellIcon,
  FaRegUserCircle as UserIcon,
} from 'react-icons/fa'
import { GiHamburgerMenu as MenuIcon } from 'react-icons/gi'
import React, { ReactElement, useState } from 'react'
import { AuthService, NotificationService, UserService } from 'service'
import { useQuery } from 'react-query'
import Logo from 'asset/logo_Bright.png'
import { NotificationBody } from './Notification'
import { ToastTheme } from '../toast'
import MenuModal from './MenuModal'
import { User } from 'model'

const navItems = [
  { title: '홈', link: '/' },
  { title: '서비스 소개', link: '/service' },
  { title: '마음일기', link: '/diary' },
  { title: '마음검사', link: '/test' },
]

const IconBtn = (icon: ReactElement, onClick: () => void, count?: string) => {
  return (
    <Box position="relative">
      <IconButton
        icon={icon}
        borderRadius={999}
        fontSize={{ base: '20px', sm: '24px' }}
        aria-label="search button"
        bg="transparent"
        _active={{ transform: 'scale(0.98)' }}
        onClick={onClick}
      />
      {count && (
        <Box
          w="20px"
          h="20px"
          bgColor={'red'}
          borderRadius="15px"
          position="absolute"
          top="-1px"
          right="1px"
          lineHeight="20px"
          textAlign="center"
          display="inline-block"
        >
          <Text color={'white'} fontSize="xs">
            {count}
          </Text>
        </Box>
      )}
    </Box>
  )
}

export function Header() {
  const navigate = useNavigate()
  const location = useLocation()
  const toast = useToast()
  const token = localStorage.getItem('access') ?? ''
  const [count, setCount] = useState<string>('')
  const [user, setUser] = useState<User>()
  const [fetchCount, setFetchCount] = useState<boolean>(true)
  const [validate, setValidate] = useState<boolean>(false)
  const isHome = React.useState<boolean>(location.pathname === '/')
  const [isMobile] = useMediaQuery('(max-width: 480px)', {
    ssr: true,
    fallback: false,
  })
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

  const openDrawer = () => {
    setIsMenuOpen(true)
  }

  const closeDrawer = () => {
    setIsMenuOpen(false)
  }

  useQuery(['user'], async () => await UserService.getUser(token), {
    enabled: token !== '',
    onSuccess: (data) => {
      setUser(data)
      if (data.coupons.length > 0) {
        setValidate(true)
      }
    },
  })

  useQuery(
    ['countUnreadNotifications'],
    async () => {
      return await NotificationService.countUnreadNotifications(token)
    },
    {
      enabled: fetchCount,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setCount(data === 0 ? '' : String(data))
        setFetchCount(false)
      },
    }
  )

  useQuery(
    ['verify'],
    async () => {
      return await AuthService.verify(token)
    },
    {
      enabled: token !== '',
      onSuccess: (data) => {
        if (data === false) {
          localStorage.setItem('access', '')
        }
      },
      onError: () => {
        localStorage.removeItem('access')
      },
    }
  )
  const isActive = (path: string) => {
    return location.pathname === path
  }

  const goPage = (link: string) => {
    navigate(link)
  }

  const NavItem = (title: string, link: string) => {
    return (
      <Text
        key={link}
        onClick={() => {
          if (!token && (title === '마음일기' || title === '마음검사')) {
            toast({
              description: '로그인 후 사용해주세요.',
              ...ToastTheme.error,
            })
            return
          }
          if (!validate && (title === '마음일기' || title === '마음검사')) {
            toast({
              description: '이용권 등록 후 사용해주세요.',
              ...ToastTheme.error,
            })
            return
          }
          goPage(link)
        }}
        as="h4"
        cursor="pointer"
        sx={{
          textDecoration: isActive(link) ? 'underline 3px' : 'none',
          textUnderlineOffset: '7px',
        }}
      >
        {title}
      </Text>
    )
  }

  return (
    <>
      {isMobile && (
        <Flex
          as="header"
          h="60px"
          bg="white"
          px="20px"
          align="center"
          justify="space-between"
          zIndex="1250"
        >
          <IconButton
            icon={<MenuIcon />}
            fontSize="24px"
            aria-label="ham button"
            bg="transparent"
            _active={{ transform: 'scale(0.98)' }}
            onClick={openDrawer}
            _hover={{ bg: 'transparent' }}
          />
          <Image
            src={Logo}
            w="90.67px"
            h="34px"
            mb="8px"
            onClick={() => {
              navigate('/')
            }}
          />
          <IconButton
            icon={<BellIcon />}
            fontSize="24px"
            aria-label="bell button"
            bg="transparent"
            _active={{ transform: 'scale(0.98)' }}
            onClick={() => {
              navigate('/my/notice')
            }}
            _hover={{ bg: 'transparent' }}
          />
          {count && (
            <Box
              w="15px"
              h="15px"
              bgColor={'red'}
              borderRadius="15px"
              position="absolute"
              top="18px"
              right="25px"
              lineHeight="15px"
              textAlign="center"
              display="inline-block"
            >
              <Text color={'white'} fontSize="10px">
                {count}
              </Text>
            </Box>
          )}
        </Flex>
      )}
      {isMobile && (
        <MenuModal
          isOpen={isMenuOpen}
          onClose={closeDrawer}
          user={user}
          setUser={setUser}
          token={token}
          validate={validate}
        />
      )}
      {!isMobile && (
        <Flex
          as="header"
          w="100%"
          minW="1280px"
          h="120px"
          position="relative"
          top="0"
          display="flex"
          align="center"
          justify="space-between"
          px={44}
          color="primary.m2"
          bg={isHome[0] ? 'transparent' : 'white'}
          zIndex="1250"
        >
          <HStack as="nav" gap={8}>
            <Image
              src={Logo}
              w="154px"
              onClick={() => {
                navigate('/')
              }}
            />
            {navItems.map((item) => {
              return NavItem(item.title, item.link)
            })}
          </HStack>
          <HStack gap={8}>
            <Popover returnFocusOnClose={false} placement="bottom">
              <PopoverTrigger>
                {IconBtn(<BellIcon />, () => {}, count)}
              </PopoverTrigger>
              <NotificationBody setFetchCount={setFetchCount} />
            </Popover>
            {IconBtn(<UserIcon />, () => {
              if (!token) {
                navigate('/login')
              } else {
                navigate('/my/account')
              }
            })}
          </HStack>
        </Flex>
      )}
    </>
  )
}
