/* eslint-disable @typescript-eslint/no-unused-vars */
import { Flex, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

type MyPath = '/my/account' | '/my/coupon'

type MyList = {
  label: string
  path: MyPath
}

const myList: MyList[] = [
  { label: '개인정보 관리', path: '/my/account' },
  { label: '이용권 관리', path: '/my/coupon' },
]

export function MobileMyNav() {
  const navigate = useNavigate()
  const location = useLocation()
  const path = String(location.pathname)

  return (
    <Flex w="100%" gap={6} align="center" px="20px" py="18px">
      {myList.map((item) => (
        <Text
          key={item.label}
          color={item.path === path ? 'primary.m2' : 'gray.g2'}
          textStyle="smallBold"
          cursor="pointer"
          whiteSpace="nowrap"
          sx={
            item.path === path
              ? {
                  textDecoration: 'underline',
                  textDecorationColor: 'primary.m2',
                  textDecorationThickness: '2px',
                  textUnderlineOffset: '10px',
                }
              : {}
          }
          onClick={() => {
            navigate(`${item.path}`)
          }}
        >
          {item.label}
        </Text>
      ))}
    </Flex>
  )
}
