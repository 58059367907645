import { Box } from '@chakra-ui/react'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { TestResult, TestType } from 'model'
import dayjs from 'dayjs'
import { TestCategory } from 'data/testCategory'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export function ResultGraph({
  name,
  result,
  isMobile,
}: {
  name: TestType
  result: TestResult[] | undefined
  isMobile: boolean
}) {
  const options = {
    responsive: true,
    parsing: {
      xAxisKey: 'createdAt',
      yAxisKey: 'score',
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          title: function (context: any) {
            const label = context.label
            return dayjs(label).format('YYYY년 MM월 DD일')
          },
          label: function (context: any) {
            const value: string = context.formattedValue
            return value + '점'
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          callback: function (value: string | number, index: number): string {
            const newthis = this as any
            return dayjs(newthis.getLabelForValue(value)).format('M월')
          },
        },
      },
      y: {
        min: 0,
        max: TestCategory[name].maxScore,
        ticks: {
          stepSize: 4,
        },
      },
    },
  }

  const data = {
    datasets: [
      {
        data: result,
        fill: false,
        borderColor: '#5078DF',
        tension: 0.1,
      },
    ],
  }
  return (
    <Box w={isMobile ? '100%' : '450px'}>
      <Line data={data} options={options} />
    </Box>
  )
}
