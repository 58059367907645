import { TestCategory, TestResultStandard } from 'data/testCategory'
import { TestType } from 'model'

const defaultResult = {
  desc: '검사결과가 없습니다',
  state: {
    color: 'gray.g2',
    text: '없음',
  },
}

export function getTestState(name: TestType, score: number) {
  if (!score) {
    return defaultResult
  }
  const idx = calculateIdx(
    score,
    TestCategory[name].standard,
    TestResultStandard[name].length - 1
  )
  return TestResultStandard[name][idx]
}
const calculateIdx = (score: number, div: number, maximum: number) => {
  const d = Number((score / div).toFixed())
  return Math.min(d, maximum)
}
