/* eslint-disable @typescript-eslint/no-unused-vars */
import { Link as RLink, useNavigate } from 'react-router-dom'
import {
  Box,
  Image,
  Spacer,
  Text,
  Link,
  Icon,
  Flex,
  Button,
  useMediaQuery,
} from '@chakra-ui/react'
import { FaAngleRight as RightIcon } from 'react-icons/fa'

import Home1 from 'asset/home_1.png'
import Home2 from 'asset/home_2.png'
import Home3 from 'asset/home_3.png'
import Home4 from 'asset/home_4.png'

import Mobile1 from 'asset/mobile1.png'
import Mobile2 from 'asset/mobile2.png'
import Mobile3 from 'asset/mobile3.png'
import Mobile4 from 'asset/mobile4.png'

type SectionItem = {
  img: string
  title: string
  content: string
  align: 'left' | 'right'
  color?: string
}
const SectionList: SectionItem[] = [
  {
    img: Home2,
    title: '안정적인 우리 팀을 위한<br/>건강한 마음 솔루션',
    content:
      '브라이트는 조직 진단과 번아웃, 생산성 등 기업의<br/>건강한 성장을 위한 마음건강 관리 솔루션을 제공합니다.',
    align: 'right',
  },
  {
    img: Home3,
    title: '서울대 정신의학과 전문의가<br/>만들면 다르니까',
    content:
      '서울대병원에서 기분장애에 대해 진료하고 연구한 정신의학과 전문의와<br/>디지털 헬스케어 전문가들이 함께 개발했습니다.',
    align: 'left',
    color: 'white',
  },
  {
    img: Home4,
    title: '익명화된 텍스트 상담으로<br/>개인정보를 안전하게',
    content:
      '회원들의 정보를 의료 데이터에 준하여 보호하며 개인을<br/>특정할 수 없는 비식별화 데이터로 나의 마음을 분석할 수 있습니다.',
    align: 'right',
  },
]

type MobileItem = {
  img: string
  title: string
  content: string
  align: 'left' | 'right' | 'center'
  color: string
  contentColor: string
}
const MobileList: MobileItem[] = [
  {
    img: Mobile1,
    title: '내 손안의 마음건강 주치의,<br/>BRIGHT',
    content:
      '지속 가능한 건강한 성장을 위한 조직의 시작은<br/>모두의 안정적인 마음으로부터 시작됩니다.',
    align: 'center',
    color: 'primary.m2',
    contentColor: '#111111',
  },
  {
    img: Mobile2,
    title: '안정적인 우리 팀을 위한<br/>건강한 마음 솔루션',
    content:
      '브라이트는 조직 진단과 번아웃, 생산성 등<br/>기업의 건강한 성장을 위한<br/>마음건강 관리 솔루션을 제공합니다.',
    align: 'right',
    color: '#111111',
    contentColor: '#111111',
  },
  {
    img: Mobile3,
    title: '서울대 정신의학과 전문의가<br/>만들면 다르니까',
    content:
      '서울대병원에서 기분장애에 대해 진료하고 연구한<br/>정신의학과 전문의와 디지털 헬스케어 전문가들이<br/>함께 개발했습니다.',
    align: 'left',
    color: 'white',
    contentColor: 'white',
  },
  {
    img: Mobile4,
    title: '익명화된 텍스트 상담으로<br/>개인정보를 안전하게',
    content:
      '회원들의 정보를 의료 데이터에 준하여 보호하며<br/>개인을 특정할 수 없는 비식별화 데이터로<br/>나의 마음을 분석할 수 있습니다.',
    align: 'right',
    color: '#111111',
    contentColor: '#111111',
  },
]

export default function HomeScreen() {
  const navigate = useNavigate()
  const [isMobile] = useMediaQuery('(max-width: 480px)', {
    ssr: true,
    fallback: false,
  })

  const Section = ({ content }: { content: SectionItem }) => {
    return (
      <Box as="section" position="relative" minW="1280px">
        <Image src={content.img} loading="lazy" />
        <Box
          position="absolute"
          top="9.5rem"
          right={content.align === 'right' ? '44' : ''}
          left={content.align === 'left' ? '44' : ''}
          textAlign={content.align}
          color={content.color ?? 'gary.g1'}
        >
          <Text
            as="h3"
            fontSize="3rem"
            lineHeight="3.5rem"
            dangerouslySetInnerHTML={{ __html: content.title }}
          ></Text>
          <Spacer h={6} />
          <Text
            as="h4"
            dangerouslySetInnerHTML={{ __html: content.content }}
          ></Text>
          <Spacer h={6} />
          <Flex
            align="center"
            color={content.color ?? 'primary.m2'}
            gap={2}
            onClick={() => {
              navigate('/service')
            }}
          >
            <Text as="b" cursor="pointer">
              자세히보기
            </Text>
            <Icon as={RightIcon} />
          </Flex>
        </Box>
      </Box>
    )
  }

  const MobileSection = ({ content }: { content: MobileItem }) => {
    return (
      <Box as="section" position="relative">
        <Image src={content.img} loading="lazy" w="100%" />
        <Flex
          flexDirection="column"
          position="absolute"
          top="32px"
          w="100%"
          px="20px"
          textAlign={content.align}
          gap="10px"
        >
          <Text
            as="h3"
            fontSize={content.align === 'center' ? '26px' : '20px'}
            color={content.color}
            dangerouslySetInnerHTML={{ __html: content.title }}
          />
          <Text
            as="h4"
            fontSize="15px"
            color={content.contentColor}
            dangerouslySetInnerHTML={{ __html: content.content }}
          />
        </Flex>
      </Box>
    )
  }

  return (
    <>
      {!isMobile && (
        <Box as="section" position="relative" minW="1280px">
          <Image src={Home1} />
          <Box position="absolute" top="18rem" left={44}>
            <Text
              as="h1"
              fontSize="4.5rem"
              fontWeight="bold"
              color="primary.m2"
              lineHeight="5.5rem"
            >
              내 손안의 <br />
              마음건강 주치의, <br />
              BRIGHT
            </Text>
            <Spacer h={4} />
            <Text as="h4">
              지속 가능한 건강한 성장을 위한 조직의 시작은
              <br />
              모두의 안정적인 마음으로부터 시작됩니다.
            </Text>
            <Spacer h={10} />
            <Link
              as={RLink}
              to={'/service'}
              sx={{ _hover: { textDecoration: 'none' } }}
            >
              <Flex align="center" color="primary.m2" gap={2}>
                <Text as="b" cursor="pointer">
                  자세히보기
                </Text>
                <Icon as={RightIcon} />
              </Flex>
            </Link>
          </Box>
        </Box>
      )}
      {!isMobile &&
        SectionList.map((item) => {
          return <Section key={item.title} content={item} />
        })}
      {isMobile &&
        MobileList.map((item) => {
          return <MobileSection key={item.title} content={item} />
        })}
      {isMobile && (
        <Box as="section">
          <Flex
            flexDirection="column"
            w="100%"
            px="20px"
            py="40px"
            align="center"
            textAlign="center"
            gap="12px"
            bg="#ECF9FF"
          >
            <Text as="h3" fontSize="20px" color="#111111">
              정신건강 전문가에게
              <br />
              직접 케어받는 나의 마음
            </Text>
            <Text as="h4" fontSize="15px" color="#111111">
              BRIGHT를 통해 여러분의 마음건강을 관리하세요.
            </Text>
            <Button
              w="240px"
              h="56px"
              bg="primary.m2"
              color="white"
              mt="10px"
              onClick={() => {
                navigate('/service')
              }}
            >
              지세히 알아보기
            </Button>
          </Flex>
        </Box>
      )}
    </>
  )
}
