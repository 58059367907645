import { Box, Spacer, Text } from '@chakra-ui/react'
import { ReactNode } from 'react'

export function Card({
  title,
  children,
}: {
  title: string
  children: ReactNode
}) {
  return (
    <Box as="section" display='inline-block' boxShadow={'sm'} borderRadius="xl" p={20}>
      <Text as="h3">{title}</Text>
      <Spacer h={6} />
      {children}
    </Box>
  )
}
