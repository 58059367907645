import dayjs from 'dayjs'
import { useRecoilValue } from 'recoil'
import { testExpiration } from 'store'
import 'dayjs/locale/ko'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.locale('ko')
dayjs.extend(customParseFormat)

export const verifyTest = (id: number) => {
  const testExp = useRecoilValue(testExpiration)

  return (
    dayjs().isAfter(dayjs(testExp[id - 1], 'YYYY년 M월 D일'), 'day') ||
    dayjs().isSame(dayjs(testExp[id - 1], 'YYYY년 M월 D일'), 'day')
  )
}
